import React from 'react';
import {
  Row,
  Col,
  Input,
  Select,
  Cascader,
  Button,
  PageHeader,
  Tag,
  Form,
  message,
  Modal,
  Space,
  Affix,
  Card,
} from 'antd';
import { cloneDeep } from 'lodash';
import { SearchOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  getIntSchoolItem,
  intSchoolSubmitItem,
  getIntSchoolOptions,
  editIntSchoolItemGeneral,
  createIntSchoolItem,
  getChinaProvinceCityList,
} from '../../services/intschool';
import { getImageSize, Storage } from '../../common/utils.js';
import FormImage from '../../component/upload-image';
import RichText from '../../component/rich-text';
import BaiduMapSearch from '../../component/baidumap-search';
import BaiduMapView from '../../component/baidumap-view';

// 审核状态
const REVIEW_STATUS_NUMBER = {
  /** 未提交 */ CREATED: 0,
  /** 已通过 */ ACCEPTED: 1,
  /** 已拒绝 */ REJECTED: 2,
  /** 待审核 */ PENDING: 3,
};

// 审核状态文字
const REVIEW_STATUS = {
  /** 未提交 */ [REVIEW_STATUS_NUMBER.CREATED]: '未提交审核',
  /** 已通过 */ [REVIEW_STATUS_NUMBER.ACCEPTED]: '已通过审核',
  /** 已拒绝 */ [REVIEW_STATUS_NUMBER.REJECTED]: '已被驳回',
  /** 待审核 */ [REVIEW_STATUS_NUMBER.PENDING]: '待审核',
};

const { Option } = Select;
const FormItem = Form.Item;

const patternInteger = /^\d+$/;
const numberToString = value => (typeof value === 'number' ? String(value) : value);
const parseToNumber = value => value && Number(value);
const validateIntegerAllowEmptyString = ({ min, max }, value) => {
  if (value === '' || value == null) {
    return Promise.resolve();
  }
  if (typeof value === 'string') {
    if (!patternInteger.test(value)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('该字段必须是整数类型');
    }
    value = Number(value);
  }
  if (!Number.isSafeInteger(value)) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('该字段必须是整数类型');
  }
  if (typeof min === 'number' && value < min) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(`该字段不能小于${min}`);
  }
  if (typeof max === 'number' && value > max) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(`该字段不能大于${max}`);
  }
  return Promise.resolve();
};

function SectionHeader({ children, style, ...props }) {
  return (
    <header
      {...props}
      style={{
        margin: '24px 0',
        fontSize: '16px',
        color: 'rgba(0,0,0,0.85)',
        fontWeight: 'bold',
        ...style,
      }}
    >
      {children}
    </header>
  );
}

const prepareFormData = data => {
  data.cityArray = [data.provinceId, data.cityId];
  data.advantages = data.advantages || [];
  data.honors = data.honors || [];
  data = cloneDeep(data);
  if (typeof data.graduateAdmissionRate === 'number') {
    data.graduateAdmissionRate *= 100;
  }
  if (typeof data.graduateAdmissionRateTop100 === 'number') {
    data.graduateAdmissionRateTop100 *= 100;
  }
  return data;
};

const transformFormData = async data => {
  if (data.cityArray) {
    data.provinceId = data.cityArray[0] || data.provinceId;
    data.cityId = data.cityArray[1] || data.cityId;
    delete data.cityArray;
  }
  data = cloneDeep(data);
  data.advantages = data.advantages || [];
  data.honors = data.honors || [];
  if (data.graduateAdmissionRate) {
    const value = Number(data.graduateAdmissionRate);
    if (!(value >= 0 && value <= 100)) {
      throw new Error('成功申请率应该是0%-100%的值');
    }
    data.graduateAdmissionRate = value / 100;
  }
  if (data.graduateAdmissionRateTop100) {
    const value = Number(data.graduateAdmissionRateTop100);
    if (!(value >= 0 && value <= 100)) {
      throw new Error('世界100强大学成功申请率应该是0%-100%的值');
    }
    data.graduateAdmissionRateTop100 = value / 100;
  }
  if (data.coverUrl) {
    const { width, height } = await getImageSize(data.coverUrl);
    data.coverWidth = width;
    data.coverHeight = height;
  }

  return data;
};

export default class IntSchoolInfoEdit extends React.Component {
  submitting = false;

  state = {
    submitting: false,
    ready: false,
    data: {},
    formData: {},
    provinceCityList: [],
    isShowingMapSearchModal: false,
    isShowingMapViewModal: false,
    mapCity: '',
    mapAddress: '',
    mapResult: '',
  };

  form = React.createRef();

  get isCreate() {
    return !this.props?.match.params?.id;
  }

  componentDidMount() {
    const { params = {} } = this.props.match;
    const actions = [getIntSchoolOptions(), getChinaProvinceCityList()];
    if (params.id) {
      actions.push(getIntSchoolItem({ id: params.id }));
    }
    Promise.all(actions).then(([options, cityList, item]) => {
      const updateState = {};
      if (item) {
        updateState.data = item;
        updateState.formData = prepareFormData({ ...item, ...item.reviewData });
      } else {
        updateState.formData = prepareFormData({});
      }
      this.setState({
        ...options,
        ...updateState,
        provinceCityList: getProvinceCityList(cityList),
        ready: true,
      });

      function getProvinceCityList(province) {
        return province.map(item => ({
          ...item,
          value: item.id,
          label: item.value,
          children: item.children ? getProvinceCityList(item.children) : null,
        }));
      }
    });
  }

  onDataCommit = submit => () => {
    if (this.submitting) {
      return;
    }
    this.submitting = true;
    this.setState({ submitting: true });
    Promise.resolve()
      .then(async () => {
        let { id } = this.props.match.params;
        const form = this.form.current;
        const data = await form
          .validateFields()
          .catch(errorData => {
            message.error(`表单存在${errorData.errorFields.length}处错误，请仔细检查`);
            throw errorData;
          })
          .then(transformFormData);

        if (this.isCreate) {
          const res = await createIntSchoolItem(data);
          id = res.id;
          if (submit) {
            await intSchoolSubmitItem({ id });
            message.success('提交成功');
          } else {
            message.success('保存成功');
          }
          window.location.replace(`/admin/international-school/-/${id}/info/edit`);
        } else {
          await editIntSchoolItemGeneral({ ...data, id });
          if (submit) {
            await intSchoolSubmitItem({ id });
            message.success('提交成功');
          } else {
            message.success('保存成功');
          }
        }
      })
      .catch(err => {
        if (err && err.message) {
          message.error(err.message);
        }
      })
      .finally(() => {
        this.submitting = false;
        this.setState({ submitting: false });
      });
  };

  renderHeaderExtra = () => {
    const { submitting } = this.state;
    return (
      <Space>
        <Button key="save-and-stay" loading={submitting} onClick={this.onDataCommit(false)}>
          保存
        </Button>
        <Button key="save-and-submit" loading={submitting} onClick={this.onDataCommit(true)}>
          提交审核
        </Button>
      </Space>
    );
  };

  renderStatusTag() {
    if (this.isCreate) {
      return <Tag color="green">新增学校</Tag>;
    }
    const { reviewStatus } = this.state.data;
    switch (reviewStatus) {
      case REVIEW_STATUS_NUMBER.CREATED:
        return <Tag color="gray">{REVIEW_STATUS[reviewStatus]}</Tag>;
      case REVIEW_STATUS_NUMBER.PENDING:
        return <Tag color="orange">{REVIEW_STATUS[reviewStatus]}</Tag>;
      case REVIEW_STATUS_NUMBER.ACCEPTED:
        return <Tag color="green">{REVIEW_STATUS[reviewStatus]}</Tag>;
      case REVIEW_STATUS_NUMBER.REJECTED:
        return <Tag color="red">{REVIEW_STATUS[reviewStatus]}</Tag>;
      default:
        return <Tag color="grey">状态异常，请联系管理员</Tag>;
    }
  }

  showLocationInMap = () => {
    const { provinceCityList } = this.state;
    const { address, cityArray } = this.form.current.getFieldsValue(['address', 'cityArray']);
    const province = provinceCityList.find(p => p.value === cityArray[0]);
    const city = province && province.children.find(c => c.value === cityArray[1]);
    if (!province || !city) {
      message.error('请先选择好所在城市');
      return;
    }
    const cityName = province.label === city.label ? province.label : province.label + city.label;
    this.setState({ isShowingMapViewModal: true, mapCity: cityName, mapAddress: address, mapResult: '' });
  };

  closeMapViewModal = () => {
    this.setState({ isShowingMapViewModal: false });
  };

  searchLocationInMap = () => {
    const { provinceCityList } = this.state;
    const { chineseName, cityArray } = this.form.current.getFieldsValue(['chineseName', 'cityArray']);
    const province = provinceCityList.find(p => p.value === cityArray[0]);
    const city = province && province.children.find(c => c.value === cityArray[1]);
    if (!province || !city) {
      message.error('请先选择好所在城市');
      return;
    }
    const cityName = province.label === city.label ? province.label : province.label + city.label;
    this.setState({ isShowingMapSearchModal: true, mapCity: cityName, mapAddress: chineseName, mapResult: '' });
  };

  closeMapSearchModal = () => {
    this.setState({ isShowingMapSearchModal: false });
  };

  onMapAddressSearch = value => {
    this.setState({ mapAddress: value });
  };

  onMapLocationSelect = poi => {
    this.setState({ mapResult: poi.address });
  };

  submitMapSearchModal = () => {
    const { mapResult } = this.state;
    this.form.current.setFieldsValue({ address: mapResult });
    this.closeMapSearchModal();
  };

  renderMapViewModal = () => {
    const { isShowingMapViewModal, mapCity, mapAddress } = this.state;

    return (
      <Modal
        width={640}
        title="查看位置"
        visible={isShowingMapViewModal}
        onCancel={this.closeMapViewModal}
        onOk={this.closeMapViewModal}
        destroyOnClose
      >
        <BaiduMapView width="100%" address={mapAddress} city={mapCity} onLocationSelect={this.onMapLocationSelect} />
      </Modal>
    );
  };

  renderMapSearchModal = () => {
    const { isShowingMapSearchModal: isShowingMapModal, mapCity, mapAddress, mapResult } = this.state;

    return (
      <Modal
        width={640}
        title="搜索位置"
        visible={isShowingMapModal}
        onCancel={this.closeMapSearchModal}
        onOk={this.submitMapSearchModal}
        destroyOnClose
      >
        <Form layout="vertical">
          <FormItem label="搜索词">
            <Input.Search defaultValue={mapAddress} onSearch={this.onMapAddressSearch} />
          </FormItem>
          <FormItem label="搜索结果（在地图中点选标记可以切换结果）">
            <Input value={mapResult} />
          </FormItem>
          <BaiduMapSearch
            width="100%"
            height={360}
            address={mapAddress}
            city={mapCity}
            onLocationSelect={this.onMapLocationSelect}
          />
        </Form>
      </Modal>
    );
  };

  render() {
    const schoolName = Storage.getItem('schoolName') || '学校';
    const { ready, formData, provinceCityList, types } = this.state;
    if (!ready) {
      return (
        <div className="container-international-school-info">
          <PageHeader
            style={{ background: 'white', borderTop: '1px solid #edf0f2' }}
            title={`${schoolName}-学校详情`}
          />
        </div>
      );
    }
    return (
      <div className="container-international-school-info">
        <Affix>
          <PageHeader
            style={{ background: 'white', borderTop: '1px solid #edf0f2', borderBottom: '1px solid #edf0f2' }}
            title={`${schoolName}-学校详情`}
            tags={this.renderStatusTag()}
            extra={this.renderHeaderExtra()}
          />
        </Affix>
        <div className="content-white-box">
          <Form layout="vertical" initialValues={formData} ref={this.form}>
            <SectionHeader style={{ marginTop: 0 }}>基本信息</SectionHeader>
            <Row gutter={32}>
              <Col flex="1 0 0">
                <FormItem label="学校中文名称" name="chineseName" rules={[{ required: true, whitespace: true }]}>
                  <Input />
                </FormItem>
                <FormItem label="学校英文名称" name="englishName" rules={[{ required: true, whitespace: true }]}>
                  <Input />
                </FormItem>
              </Col>
              <Col flex="0 0 auto">
                <FormItem label="学校LOGO" name="logo" rules={[{ required: true, type: 'url' }]}>
                  <FormImage style={{ width: 118, height: 118 }} preset="common-image" max={1} />
                </FormItem>
              </Col>
              <Col flex="0 0 auto">
                <FormItem label="学校封面" name="coverUrl" rules={[{ required: true, type: 'url' }]}>
                  <FormImage style={{ width: 118, height: 118 }} preset="common-image" max={1} />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={6}>
                <FormItem
                  label="建校年份"
                  name="establishmentYear"
                  rules={[
                    {
                      required: true,
                      type: 'integer',
                      min: 1000,
                      max: new Date().getFullYear(),
                      transform: parseToNumber,
                    },
                  ]}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="学校面积" name="area">
                  <Input />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem label="办学性质" name="typeId" rules={[{ type: 'string', required: true }]}>
                  <Select>
                    {(types || []).map((item, index) => (
                      <Option key={index} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label="在读学生人数"
                  name="totalStudentCount"
                  rules={[{ min: 1, transform: numberToString, validator: validateIntegerAllowEmptyString }]}
                >
                  <Input />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={6}>
                <FormItem
                  label="师生比例"
                  name="facultyStudentRatio"
                  rules={[{ pattern: /^(\d+(?:\.\d+)?):(\d+(?:\.\d+)?)$/, message: '师生比例必须是 X:Y 的格式' }]}
                >
                  <Input placeholder="教师数:学生数（大致比例）" />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label="外教比例"
                  name="foreignTeacherRatio"
                  rules={[{ pattern: /^(\d+(?:\.\d+)?)%$/, message: '外教比例必须是百分比格式' }]}
                >
                  <Input placeholder="外教占全体教师的百分比" />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="学校官网" name="website" rules={[{ type: 'url' }]}>
                  <Input />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={6}>
                <FormItem label="是否接收外籍" name="acceptForeign" rules={[{ type: 'boolean' }]}>
                  <Select>
                    <Option value={null}>不清楚</Option>
                    <Option value>是</Option>
                    <Option value={false}>否</Option>
                  </Select>
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label="所在城市"
                  name="cityArray"
                  rules={[
                    {
                      required: true,
                      type: 'array',
                      len: 2,
                    },
                  ]}
                >
                  <Cascader options={provinceCityList} />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="详细地址" name="address" rules={[{ required: true, whitespace: true }]}>
                  <Input
                    addonBefore={<SearchOutlined onClick={this.searchLocationInMap} />}
                    addonAfter={<EyeOutlined onClick={this.showLocationInMap} />}
                  />
                </FormItem>
              </Col>
            </Row>
            <hr style={{ margin: '16px 0 40px', borderColor: '#edf0f2' }} />
            <SectionHeader>学校优势</SectionHeader>
            <Form.List name="advantages">
              {(fields, { add, remove }) => (
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 16 }}>
                  {fields.map(field => (
                    <Card
                      title={`学校优势${field.key + 1}`}
                      extra={<DeleteOutlined className="color-red6" onClick={() => remove(field.name)} />}
                      headStyle={{ fontSize: 14, background: '#fafafa', padding: '0 20px' }}
                      bodyStyle={{ paddingBottom: 0 }}
                    >
                      <Row gutter={16}>
                        <Col flex="1 0 0">
                          <FormItem
                            label="优势标题"
                            name={[field.name, 'title']}
                            fieldKey={[field.fieldKey, 'title']}
                            rules={[{ required: true, whitespace: true }]}
                          >
                            <Input />
                          </FormItem>

                          <FormItem
                            label="优势说明"
                            name={[field.name, 'description']}
                            fieldKey={[field.fieldKey, 'description']}
                            rules={[{ required: true, whitespace: true }]}
                          >
                            <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
                          </FormItem>
                        </Col>
                        <Col>
                          <FormItem
                            label="优势图片"
                            name={[field.name, 'image']}
                            fieldKey={[field.fieldKey, 'image']}
                            rules={[{ required: true, type: 'url' }]}
                          >
                            <FormImage preset="common-image" max={1} />
                          </FormItem>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                  <Button
                    type="dashed"
                    onClick={() => add({})}
                    style={{ placeSelf: 'stretch', height: 'unset', minHeight: 280 }}
                  >
                    添加优势
                  </Button>
                </div>
              )}
            </Form.List>
            <hr style={{ margin: '40px 0', borderColor: '#edf0f2' }} />
            <SectionHeader>荣誉资质</SectionHeader>
            <Form.List name="honors">
              {(fields, { add, remove }) => (
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 32 }}>
                  {fields.map(field => (
                    <Card
                      title={`荣誉资质${field.key + 1}`}
                      extra={<DeleteOutlined className="color-red6" onClick={() => remove(field.name)} />}
                      headStyle={{ fontSize: 14, background: '#fafafa', padding: '0 20px' }}
                      bodyStyle={{ paddingBottom: 0 }}
                    >
                      <Row gutter={16}>
                        <Col flex="1 0 0">
                          <FormItem label="荣誉标题" required>
                            <Input.Group>
                              <Row gutter={32}>
                                <Col flex="1 0 0">
                                  <FormItem
                                    noStyle
                                    name={[field.name, 'title']}
                                    fieldKey={[field.fieldKey, 'title']}
                                    rules={[{ required: true, whitespace: true }]}
                                  >
                                    <Input />
                                  </FormItem>
                                </Col>
                              </Row>
                            </Input.Group>
                          </FormItem>
                          <FormItem
                            label="荣誉说明"
                            name={[field.name, 'description']}
                            fieldKey={[field.fieldKey, 'description']}
                            rules={[{ required: true, whitespace: true }]}
                          >
                            <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
                          </FormItem>
                        </Col>
                        <Col>
                          <FormItem
                            label="荣誉图片"
                            name={[field.name, 'image']}
                            fieldKey={[field.fieldKey, 'image']}
                            rules={[{ required: true, type: 'url' }]}
                          >
                            <FormImage preset="common-image" max={1} />
                          </FormItem>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                  <Button
                    type="dashed"
                    onClick={() => add({})}
                    style={{ placeSelf: 'stretch', height: 'unset', minHeight: 280 }}
                  >
                    添加荣誉
                  </Button>
                </div>
              )}
            </Form.List>
            <hr style={{ margin: '40px 0', borderColor: '#edf0f2' }} />
            <SectionHeader>学校简介</SectionHeader>
            <FormItem
              name="introduction"
              rules={[
                {
                  required: true,
                  validator: (rule, value) =>
                    // eslint-disable-next-line prefer-promise-reject-errors
                    RichText.isEmpty(value) ? Promise.reject('请输入学校简介') : Promise.resolve(),
                },
              ]}
            >
              <RichText />
            </FormItem>
          </Form>
          <footer className="page-footer">{this.renderHeaderExtra()}</footer>
          {this.renderMapSearchModal()}
          {this.renderMapViewModal()}
        </div>
      </div>
    );
  }
}
