import React, { useState, useCallback } from 'react';

import {
  ArrowLeftOutlined,
  LogoutOutlined,
  SolutionOutlined,
  UserOutlined,
  CalendarOutlined,
  QuestionCircleOutlined,
  HomeOutlined,
  RiseOutlined,
  FileImageOutlined,
  TeamOutlined,
  ProjectOutlined,
  HighlightOutlined,
  SnippetsOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SmileOutlined,
} from '@ant-design/icons';

import { Layout, Menu, Popconfirm } from 'antd';
import { Route, Switch, withRouter } from 'react-router-dom';
import event from 'events-manage';
import BrowserTip from '../component/browser-tip/browser-tip';
import ErrorBoundary from '../component/error-boundaries/error';
import { isChrome } from '../common/utils';
import { LoginContext } from '../context/loginContext';
import Login from './login/login';
import './index.less';

import SchoolInfoEdit from './intschool/school_info_edit';
import List from './intschool/list';
import Data from './intschool/data';
import Photos from './intschool/photos';
import OutstandingGraduates from './intschool/outstanding_graduates';
import Community from './intschool/community';
import AdmissionsProject from './intschool/admissions_project';
import Teacher from './intschool/teacher';
import Article from './intschool/article';
import Faq from './intschool/faq';
import OpenDay from './intschool/open_day';

const { Header, Content, Sider } = Layout;

const getMenu = selectedMenuKeys => {
  const menu = [
    {
      title: '首页',
      icon: <SolutionOutlined />,
      key: 'list',
    },
    {
      title: '学校详情',
      icon: <HomeOutlined />,
      key: 'detail',
    },
    {
      title: '升学数据',
      icon: <RiseOutlined />,
      key: 'data',
    },
    {
      title: '学校图片',
      icon: <FileImageOutlined />,
      key: 'photos',
    },
    {
      title: '优秀毕业生',
      icon: <UserOutlined />,
      key: 'graduates',
    },
    {
      title: '学生社团',
      icon: <TeamOutlined />,
      key: 'community',
    },
    {
      title: '招生项目',
      icon: <ProjectOutlined />,
      key: 'project',
    },
    {
      title: '师资力量',
      icon: <HighlightOutlined />,
      key: 'teacher',
    },
    {
      title: '文章管理',
      icon: <SnippetsOutlined />,
      key: 'article',
    },
    {
      title: '常见问题',
      icon: <QuestionCircleOutlined />,
      key: 'faq',
    },
    {
      title: '开放日',
      icon: <CalendarOutlined />,
      key: 'open_day',
    },
  ];

  if (selectedMenuKeys[1]) {
    return menu;
  }
  return [menu[0]];
};

function ContainerIndex({ location, history }) {
  // 登录控制
  const {
    state: { userInfo = {}, isSigned },
    dispatch: loginDispatch,
  } = React.useContext(LoginContext);

  const getSelectedMenuKeys = useCallback(() => {
    let keys = location.pathname.split('/').filter(Boolean);
    if (keys.length === 0) {
      keys = ['list'];
    }
    return keys;
  }, [location.pathname]);

  const [selectedMenuKeys, setSelectedMenuKeys] = useState(getSelectedMenuKeys());

  const [collapsed, setCollapsed] = useState(false);

  // 监听一次登出处理事件
  const logoutCount = event.getListenerCount('logout');
  if (!logoutCount) {
    event.on('logout', () => {
      loginDispatch({ type: 'logout' });
    });
  }

  React.useEffect(() => {
    setSelectedMenuKeys(getSelectedMenuKeys());
  }, [getSelectedMenuKeys, location]);

  // 未登录的情况
  if (!isSigned) {
    return <Login />;
  }

  // 处理点击菜单事件
  function handleClickMenu({ key, keyPath }) {
    let pathname = location.pathname.split('/').filter(Boolean);
    if (key !== pathname[0]) {
      const id = key === 'list' ? null : pathname[1];
      setSelectedMenuKeys(keyPath);
      const path = key === 'list' ? '' : key;
      history.push(`/${path}${id ? `/${id}` : ''}`);
    }
  }

  // 已登录，进入系统
  const menu = getMenu(location.pathname.split('/').filter(Boolean));
  return (
    <Layout style={{ height: '100vh' }} className="container-index-layout">
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
        trigger={
          <div style={{ padding: '0 16px', textAlign: 'left' }}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div>
        }
      >
        {userInfo?.name ? (
          <div className="profile-box">
            {collapsed ? (
              <div className="user-icon">
                <SmileOutlined />
              </div>
            ) : (
              <div className="username oneline">您好，{userInfo?.name}</div>
            )}
          </div>
        ) : null}
        <Menu theme="dark" mode="vertical" onClick={handleClickMenu} selectedKeys={selectedMenuKeys}>
          {menu.map(m => (
            <Menu.Item key={m.key}>
              {m.icon}
              <span>{m.title}</span>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout style={{ minHeight: '100vh' }}>
        <Header className="header">
          {location.pathname.split('/').filter(Boolean).length > 1 ? (
            <div className="back" onClick={() => history.goBack()}>
              <ArrowLeftOutlined />
              <span>返回</span>
            </div>
          ) : null}
          <div className="user">
            {userInfo?.name || userInfo?.phone || ''}
            <Popconfirm
              title="确认退出登录吗？"
              okText="确认"
              cancelText="取消"
              placement="bottomRight"
              onConfirm={() => loginDispatch({ type: 'logout' })}
            >
              <span className="logout-btn">
                <span style={{ marginRight: 5, marginLeft: 16 }}>退出登录</span>
                <LogoutOutlined />
              </span>
            </Popconfirm>
          </div>
        </Header>
        <Content className="content" style={{ overflow: 'visible' }}>
          <div className="content-box">
            <ErrorBoundary>
              <Switch>
                <Route path="/" exact component={List} />
                <Route path="/detail/:id" exact component={SchoolInfoEdit} />
                <Route path="/data/:id" exact component={Data} />
                <Route path="/photos/:id" exact component={Photos} />
                <Route path="/graduates/:id" exact component={OutstandingGraduates} />
                <Route path="/community/:id" exact component={Community} />
                <Route path="/project/:id" exact component={AdmissionsProject} />
                <Route path="/teacher/:id" exact component={Teacher} />
                <Route path="/article/:id" exact component={Article} />
                <Route path="/faq/:id" exact component={Faq} />
                <Route path="/open_day/:id" exact component={OpenDay} />
                {/* todo: <Route component={NoMatch} /> */}
              </Switch>
            </ErrorBoundary>
          </div>
          {!isChrome() ? <BrowserTip /> : null}
        </Content>
      </Layout>
    </Layout>
  );
}

export default withRouter(ContainerIndex);
