import React from 'react';
import { Row, Col, Button, Input, Select, PageHeader, Form, Space, Popover, message, Affix } from 'antd';
import { QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { debounce, cloneDeep } from 'lodash';
import {
  getIntSchoolItem,
  getIntSchoolOptions,
  getSchoolList,
  editIntSchoolItemGraduate,
  intSchoolSubmitItem,
} from '../../services/intschool';
import { Storage } from '../../common/utils';

const FormItem = Form.Item;
const Option = Select.Option;

function PopoverContent({ title = '', content }) {
  return (
    <Space>
      <span>{title}</span>
      <Popover title={title} overlayStyle={{ width: 350 }} content={content}>
        <QuestionCircleOutlined />
      </Popover>
    </Space>
  );
}

const patternInteger = /^\d+$/;
const numberToString = value => (typeof value === 'number' ? String(value) : value);
const parseToNumber = value => value && Number(value);

const transformFormData = async data => {
  data = cloneDeep(data);
  if (data.graduateAdmissionRate) {
    const value = Number(data.graduateAdmissionRate);
    if (!(value >= 0 && value <= 100)) {
      throw new Error('成功申请率应该是0%-100%的值');
    }
    data.graduateAdmissionRate = value / 100;
  }
  if (data.graduateAdmissionRateTop100) {
    const value = Number(data.graduateAdmissionRateTop100);
    if (!(value >= 0 && value <= 100)) {
      throw new Error('世界100强大学成功申请率应该是0%-100%的值');
    }
    data.graduateAdmissionRateTop100 = value / 100;
  }
  data.graduateAdmissionCountries = data.graduateAdmissionCountries || [];
  data.graduateAdmissionSchools = data.graduateAdmissionSchools || [];
  let totalRatio = 0;
  data.graduateAdmissionCountries.forEach(item => {
    item.ratio /= 100;
    totalRatio += item.ratio;
  });
  if (data.graduateAdmissionCountries.length > 0 && totalRatio.toFixed(2) !== '1.00') {
    throw new Error('升学国家比例之和应该是100%');
  }
  data.graduateAdmissionSchools.forEach(item => {
    item.school = {
      id: item.school.key,
      chineseName: item.school.label,
    };
    item.schoolId = item.school.id;
  });

  return data;
};

const validateIntegerAllowEmptyString = ({ min, max }, value) => {
  if (value === '' || value == null) {
    return Promise.resolve();
  }
  if (typeof value === 'string') {
    if (!patternInteger.test(value)) {
      return Promise.reject('该字段必须是整数类型');
    }
    value = Number(value);
  }
  if (!Number.isSafeInteger(value)) {
    return Promise.reject('该字段必须是整数类型');
  }
  if (typeof min === 'number' && value < min) {
    return Promise.reject(`该字段不能小于${min}`);
  }
  if (typeof max === 'number' && value > max) {
    return Promise.reject(`该字段不能大于${max}`);
  }
  return Promise.resolve();
};

const validateNumberAllowEmptyString = ({ min, max }, value) => {
  if (value === '' || value == null) {
    return Promise.resolve();
  }
  if (typeof value === 'string') {
    value = Number(value);
  }
  if (!Number.isFinite(value)) {
    return Promise.reject('该字段必须是数字类型');
  }
  if (typeof min === 'number' && value < min) {
    return Promise.reject(`该字段不能小于${min}`);
  }
  if (typeof max === 'number' && value > max) {
    return Promise.reject(`该字段不能大于${max}`);
  }
  return Promise.resolve();
};

function SectionHeader({ children, style, ...props }) {
  return (
    <header
      {...props}
      style={{
        margin: '24px 0',
        fontSize: '16px',
        color: 'rgba(0,0,0,0.85)',
        fontWeight: 'bold',
        ...style,
      }}
    >
      {children}
    </header>
  );
}

const prepareFormData = data => {
  data = cloneDeep(data);
  if (typeof data.graduateAdmissionRate === 'number') {
    data.graduateAdmissionRate *= 100;
  }
  if (typeof data.graduateAdmissionRateTop100 === 'number') {
    data.graduateAdmissionRateTop100 *= 100;
  }
  data.graduateAdmissionCountries = data.graduateAdmissionCountries || [];
  data.graduateAdmissionSchools = data.graduateAdmissionSchools || [];
  data.graduateAdmissionCountries.forEach(item => {
    item.ratio *= 100;
  });
  data.graduateAdmissionSchools.forEach(item => {
    item.school = {
      key: item.school ? item.school.id : item.schoolId,
      label: item.school ? item.school.chineseName : `学校#${item.schoolId}`,
    };
  });
  return data;
};

export default class Data extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectSchool: [],
      countries: [],
    };

    this.form = React.createRef();
  }

  componentDidMount() {
    const { params = {} } = this.props.match;
    const actions = [getIntSchoolOptions()];
    if (params.id) {
      actions.push(getIntSchoolItem({ id: params.id }));
    }
    Promise.all(actions).then(([options, item]) => {
      const updateState = {};
      if (item) {
        updateState.data = item;
        updateState.formData = prepareFormData({ ...item, ...item.reviewData });
      } else {
        updateState.formData = prepareFormData({});
      }
      this.setState(
        {
          ...options,
          ...updateState,
        },
        () => {
          this.form.current.setFieldsValue(updateState.formData);
        }
      );
    });
  }

  searchAddSchool = keyword => {
    getSchoolList({ keyword }).then(res => {
      this.setState({
        selectSchool: res.data,
      });
    });
  };

  onDataCommit = submit => async () => {
    const { params: { id = '' } = {} } = this.props.match || {};
    const data = await this.form.current
      .validateFields()
      .catch(errorData => {
        message.error(`表单存在${errorData.errorFields.length}处错误，请仔细检查`);
        throw errorData;
      })
      .then(transformFormData);
    await editIntSchoolItemGraduate({ ...data, id });
    if (submit) {
      await intSchoolSubmitItem({ id });
      message.success('提交成功');
    } else {
      message.success('保存成功');
    }
  };

  render() {
    const { countries, selectSchool } = this.state;
    const schoolName = Storage.getItem('schoolName') || '学校';
    return (
      <div className="container-international-school-info">
        <Affix>
          <PageHeader
            style={{ borderTop: '1px solid #edf0f2', borderBottom: '1px solid #edf0f2', background: 'white' }}
            title={`${schoolName}-升学数据`}
            extra={
              <Space style={{ marginTop: 16 }}>
                <Button key="save-and-stay" onClick={this.onDataCommit(false)}>
                  保存
                </Button>
                <Button key="save-and-submit" onClick={this.onDataCommit(true)}>
                  提交审核
                </Button>
              </Space>
            }
          />
        </Affix>
        <div className="content-white-box">
          <Form layout="vertical" ref={this.form}>
            <SectionHeader>升学情况</SectionHeader>
            <Row gutter={16}>
              <Col span={8}>
                <FormItem
                  label={<PopoverContent title="毕业总届数" content="自学校成立以来，总共毕业的届数。" />}
                  name="graduatedSessionCount"
                  rules={[{ min: 0, transform: numberToString, validator: validateIntegerAllowEmptyString }]}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label={<PopoverContent title="成功录取率" content="自学校成立以来，学生成功被海外大学录取的比率。" />}
                  name="graduateAdmissionRate"
                  rules={[{ min: 0, max: 100, transform: numberToString, validateNumberAllowEmptyString }]}
                >
                  <Input suffix="%" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  label={
                    <PopoverContent
                      title="世界TOP100大学录取率"
                      content="自学校成立以来，学生成功被世界TOP100大学录取的比率。"
                    />
                  }
                  name="graduateAdmissionRateTop100"
                  rules={[{ min: 0, max: 100, transform: numberToString, validateNumberAllowEmptyString }]}
                >
                  <Input suffix="%" />
                </FormItem>
              </Col>
            </Row>
            <hr style={{ margin: '16px 0 40px', borderColor: '#edf0f2' }} />
            <SectionHeader>
              <PopoverContent title="升学国家比例" content="自学校成立以来，学生被录取的国家的比例。" />
            </SectionHeader>
            <Form.List name="graduateAdmissionCountries">
              {(fields, { add, remove }) => (
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 32 }}>
                  {fields.map(field => (
                    <div key={field.key}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <FormItem
                            label="升学国家"
                            name={[field.name, 'countryId']}
                            fieldKey={[field.fieldKey, 'countryId']}
                            rules={[{ required: true }]}
                          >
                            <Select>
                              {countries.map((item, idx) => (
                                <Option key={idx} value={item.id}>
                                  {item.name}
                                </Option>
                              ))}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem label="所占比例" required>
                            <Input.Group>
                              <Row gutter={16}>
                                <Col flex="1 0 0">
                                  <FormItem
                                    noStyle
                                    name={[field.name, 'ratio']}
                                    fieldKey={[field.fieldKey, 'ratio']}
                                    rules={[
                                      {
                                        required: true,
                                        type: 'number',
                                        min: 0,
                                        max: 100,
                                        transform: parseToNumber,
                                      },
                                    ]}
                                  >
                                    <Input suffix="%" />
                                  </FormItem>
                                </Col>
                                <Col flex="0 0 auto">
                                  <DeleteOutlined
                                    style={{ lineHeight: '32px' }}
                                    className="color-red6"
                                    onClick={() => remove(field.name)}
                                  />
                                </Col>
                              </Row>
                            </Input.Group>
                          </FormItem>
                        </Col>
                      </Row>
                    </div>
                  ))}
                  {fields.length < 8 ? (
                    <Button type="dashed" onClick={() => add({})} style={{ width: '100%', height: 72 }}>
                      添加升学国家
                    </Button>
                  ) : null}
                </div>
              )}
            </Form.List>
            <hr style={{ margin: '16px 0 40px', borderColor: '#edf0f2' }} />
            <SectionHeader>毕业生录取院校与录取人数</SectionHeader>
            <Form.List name="graduateAdmissionSchools">
              {(fields, { add, remove }) => (
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 32 }}>
                  {fields.map(field => (
                    <div key={field.key}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <FormItem
                            label="录取学校"
                            name={[field.name, 'school']}
                            fieldKey={[field.fieldKey, 'school']}
                            rules={[{ required: true }]}
                          >
                            <Select
                              allowClear
                              showSearch
                              filterOption={false}
                              onSearch={debounce(this.searchAddSchool, 250)}
                              autoClearSearchValue
                              labelInValue
                            >
                              {selectSchool.map((item, idx) => (
                                <Option key={idx} value={item.id}>
                                  {item.chineseName}
                                </Option>
                              ))}
                            </Select>
                          </FormItem>
                        </Col>
                        <Col span={12}>
                          <FormItem label="累计录取" required>
                            <Input.Group>
                              <Row gutter={16}>
                                <Col flex="1 0 0">
                                  <FormItem
                                    noStyle
                                    name={[field.name, 'count']}
                                    fieldKey={[field.fieldKey, 'count']}
                                    rules={[
                                      {
                                        required: true,
                                        type: 'integer',
                                        min: 1,
                                        transform: parseToNumber,
                                      },
                                    ]}
                                  >
                                    <Input suffix="人" />
                                  </FormItem>
                                </Col>
                                <Col flex="0 0 auto">
                                  <DeleteOutlined
                                    style={{ lineHeight: '32px' }}
                                    className="color-red6"
                                    onClick={() => remove(field.name)}
                                  />
                                </Col>
                              </Row>
                            </Input.Group>
                          </FormItem>
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <Button type="dashed" onClick={() => add({})} style={{ width: '100%', height: 72 }}>
                    添加录取院校
                  </Button>
                </div>
              )}
            </Form.List>
          </Form>
          <Space style={{ marginTop: 16 }}>
            <Button key="save-and-stay" onClick={this.onDataCommit(false)}>
              保存
            </Button>
            <Button key="save-and-submit" onClick={this.onDataCommit(true)}>
              提交审核
            </Button>
          </Space>
        </div>
      </div>
    );
  }
}
