import React from 'react';
import { Input, Form } from 'antd';
import FormImage from '../../component/upload-image';
import { photo } from '../../services/intschool';
import { getImageSize } from '../../common/utils.js';
import ItemListContainer from './component/item_list_container';
import ImagePreview from '../../component/image_preview';

function PhotoForm({ disabled }) {
  return (
    <>
      <Form.Item label="图片" name="imageUrl" rules={[{ required: true, whitespace: true, message: '请选择图片' }]}>
        <FormImage disabled={disabled} preset="common-image" max={1} />
      </Form.Item>
      <Form.Item label="注释" name="text">
        <Input.TextArea disabled={disabled} autoSize={{ minRows: 3, maxRows: 6 }} />
      </Form.Item>
    </>
  );
}
export default function PhotoList({
  match: {
    params: { id },
  },
}) {
  return (
    <ItemListContainer
      name="图片"
      columns={[
        {
          title: '图片',
          key: 'imageUrl',
          width: '140px',
          compare: true,
          render: value => (value ? <ImagePreview src={value} /> : null),
        },
        { title: '注释', key: 'text', compare: true },
      ]}
      api={photo}
      intSchoolId={id}
      form={PhotoForm}
      transformOutput={async photoObj => {
        const { width, height } = await getImageSize(photoObj.imageUrl);
        photoObj.width = width;
        photoObj.height = height;
      }}
    />
  );
}
