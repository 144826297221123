/* eslint-disable max-classes-per-file */
import React from 'react';
import { Input, Form, Select, Popover } from 'antd';
import RichText from '../../component/rich-text';
import FormImage from '../../component/upload-image';
import { program } from '../../services/intschool';
import ItemListContainer from './component/item_list_container';
import QrCode from '../../component/qrcode';
import Time from '../../component/time';
import { appConfig } from '../../common/config';

const { Item } = Form;

function getProgramForm({ options }) {
  const { departments = [], courseSystems = [], targetRegions = [], boarderTypes = [], feeTuitions = [] } = options;

  return function ProgramForm({ disabled }) {
    return (
      <>
        <Item
          label="项目封面"
          name="coverUrl"
          rules={[{ required: true, whitespace: true, message: '请选择项目封面' }]}
        >
          <FormImage disabled={disabled} preset="common-image" max={1} />
        </Item>
        <Item label="项目名称" name="name" rules={[{ required: true, whitespace: true, message: '请输入项目名称' }]}>
          <Input disabled={disabled} />
        </Item>
        <Item
          label="开设年级"
          name="departmentId"
          rules={[{ required: true, whitespace: true, type: 'number', message: '请选择开设年级' }]}
        >
          <Select disabled={disabled}>
            {departments.map((department, index) => {
              return (
                <Select.Option key={`department-${index}`} value={department.id}>
                  {department.name}
                </Select.Option>
              );
            })}
          </Select>
        </Item>
        <Item
          label="课程体系"
          name="courseSystemIds"
          rules={[{ whitespace: true, message: '请选择课程体系', type: 'array' }]}
        >
          <Select mode="multiple" disabled={disabled}>
            {courseSystems.map((courseSystem, index) => {
              return (
                <Select.Option key={`course-system-${index}`} value={courseSystem.id}>
                  {courseSystem.name}
                </Select.Option>
              );
            })}
          </Select>
        </Item>
        <Item label="录取方式" name="enrollmentRequirement" rules={[{ whitespace: true, message: '请输入录取方式' }]}>
          <Input disabled={disabled} />
        </Item>
        <Item
          label="留学国家"
          name="targetRegionIds"
          rules={[{ whitespace: true, type: 'array', message: '请选择留学国家' }]}
        >
          <Select mode="multiple" disabled={disabled}>
            {targetRegions.map((region, index) => {
              return (
                <Select.Option key={`course-system-${index}`} value={region.id}>
                  {region.name}
                </Select.Option>
              );
            })}
          </Select>
        </Item>
        <Item
          label="住校类型"
          name="boarderTypeId"
          rules={[{ whitespace: true, type: 'number', message: '请选择住校类型' }]}
        >
          <Select disabled={disabled} allowClear>
            {boarderTypes.map((boarder, index) => {
              return (
                <Select.Option key={`boarder-type-${index}`} value={boarder.id}>
                  {boarder.name}
                </Select.Option>
              );
            })}
          </Select>
        </Item>
        <Item label="招生时间" name="enrollmentTime" rules={[{ whitespace: true, message: '请输入招生时间' }]}>
          <Input disabled={disabled} />
        </Item>
        <Item label="开学时间" name="entranceTime" rules={[{ whitespace: true, message: '请输入入学时间' }]}>
          <Input disabled={disabled} />
        </Item>
        <Item
          label="班级大小"
          name="classSize"
          rules={[{ required: true, whitespace: true, message: '请输入班级大小' }]}
        >
          <Input disabled={disabled} />
        </Item>
        <Item
          label="授课形式"
          name="teachingForm"
          rules={[{ required: true, whitespace: true, message: '请输入授课形式' }]}
        >
          <Input disabled={disabled} />
        </Item>
        <Item
          label="是否接受转学"
          name="acceptTransfer"
          rules={[{ whitespace: true, type: 'boolean', message: '请选择是否接受转学' }]}
        >
          <Select disabled={disabled} allowClear>
            <Select.Option value>是</Select.Option>
            <Select.Option value={false}>否</Select.Option>
          </Select>
        </Item>
        <Item label="项目优势" name="advantages">
          <Select
            disabled={disabled}
            mode="tags"
            placeholder="输入多个项目优势，每项以回车结束"
            dropdownStyle={{ display: 'none' }}
          />
        </Item>
        <Item
          label="学费"
          name="feeTuitionId"
          rules={[{ whitespace: true, type: 'number', message: '请选择项目学费' }]}
        >
          <Select disabled={disabled} allowClear>
            {feeTuitions.map((feeTuition, index) => {
              return (
                <Select.Option key={`fee-tuition-${index}`} value={feeTuition.id}>
                  {feeTuition.name}
                </Select.Option>
              );
            })}
          </Select>
        </Item>
        <Item label="报名费" name="feeApplication">
          <Input disabled={disabled} />
        </Item>
        <Item label="住宿费" name="feeAccommodation">
          <Input disabled={disabled} />
        </Item>
        <Item label="其他费用" name="feeOther">
          <Input disabled={disabled} />
        </Item>
        <Item label="招生对象" name="enrollmentTarget">
          <Input disabled={disabled} />
        </Item>
        <Item label="招生简章" name="admissionBrochure">
          <RichText disabled={disabled} placeholder="添加其他重要信息，比如展开介绍项目优势，说明项目的具体课程等等" />
        </Item>
      </>
    );
  };
}

export default class ProgramsList extends React.Component {
  constructor(props) {
    super(props);
    const {
      params: { id = '' },
    } = this.props.match;
    this.intSchoolId = id;
    this.state = {};

    // form表单数据
    this.formData = {};

    program.getOptions().then(data => {
      this.setState({ options: data });
    });
  }

  onFormDataChange = (key, value) => {
    this.formData[key] = value;
  };

  getInitialValues = () => {
    const item = {};
    item.advantages = [];
    item.courseSystemIds = [];
    item.targetRegionIds = [];
    item.admissionBrochure = '';
    return item;
  };

  transformInput = item => {
    item.advantages = item.advantages || [];
    item.courseSystemIds = item.courseSystemIds || [];
    item.targetRegionIds = item.targetRegionIds || [];
    item.admissionBrochure = item.admissionBrochure || '';
  };

  render() {
    const { options = {} } = this.state;
    const { departments = [] } = options;
    return (
      <ItemListContainer
        name="课程项目"
        columns={[
          {
            title: '项目名称',
            key: 'name',
            render: (value, { id, isReviewData, pvcode = '' }) => {
              if (!isReviewData) {
                return value;
              }
              return (
                <Popover
                  trigger="click"
                  content={
                    <div style={{ textAlign: 'center' }}>
                      <span>手机扫码查看页面</span>
                      <QrCode text={`${appConfig.frontend}/international-school/course/${id}?previewCode=${pvcode}`} />
                    </div>
                  }
                >
                  <span className="color-blue6">{value}</span>
                </Popover>
              );
            },
          },
          {
            title: '开设年级',
            key: 'departmentId',
            width: '90px',
            render: (__, row) => {
              const option = departments.find(d => d.id === row.departmentId);
              return option && option.name;
            },
          },
          {
            title: '创建时间',
            key: 'createTime',
            width: '120px',
            render: createTime => <Time time={createTime} />,
          },
        ]}
        api={program}
        intSchoolId={this.intSchoolId}
        form={getProgramForm({ options })}
        transformInput={this.transformInput}
        getInitialValues={this.getInitialValues}
        wideModal
      />
    );
  }
}
