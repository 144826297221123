import { appConfig } from './config';
import { loadScriptTag } from './utils';

/* global BMap */

const ak = appConfig.baidumap?.ak || '';
let __geocoder__ = null;

export const bmapInit = async () => {
  try {
    await loadScriptTag(`https://api.map.baidu.com/api?v=3.0&ak=${ak}`);
    await loadScriptTag(`https://api.map.baidu.com/getscript?v=3.0&ak=${ak}&services=`);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    throw new Error('无法初始化百度地图API');
  }
};

export const bmapCreateMap = async (container, options) => {
  await bmapInit();
  const map = new BMap.Map(container, { enableBizAuthLogo: false, ...options });
  map.addControl(new BMap.NavigationControl());
  return map;
};

export const bmapCreatePoint = async (lng, lat) => {
  await bmapInit();
  return new BMap.Point(lng, lat);
};

export const bmapCreateMarker = async (point, options) => {
  await bmapInit();
  return new BMap.Marker(point, options);
};

export const bmapCreateLocalSearch = async (map, options) => {
  await bmapInit();
  return new BMap.LocalSearch(map, options);
};

const getGeocoder = async () => {
  if (!__geocoder__) {
    await bmapInit();
    __geocoder__ = new BMap.Geocoder();
  }
  return __geocoder__;
};

export const bmapAddressToPoint = async (address, city) => {
  const geo = await getGeocoder();
  return new Promise((resolve, reject) => {
    geo.getPoint(
      address,
      point => {
        if (point) {
          resolve(point);
        } else {
          reject(new Error(`无法获取位置：${address}`));
        }
      },
      city
    );
  });
};
