import React from 'react';
import { Tooltip } from 'antd';
import moment from 'moment';

export default function Time({ time = '', ...props }) {
  if (!time) {
    return <span {...props}>-</span>;
  }
  return (
    <Tooltip title={moment(time).format('YYYY-MM-DD HH:mm:ss')}>
      <span {...props}>{moment(time).format('YYYY-MM-DD')}</span>
    </Tooltip>
  );
}
