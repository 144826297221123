import React from 'react';
import { Input, Form, Select, DatePicker } from 'antd';
import moment from 'moment';
import { openDay } from '../../services/intschool';
import ItemListContainer from './component/item_list_container';

const { Item } = Form;

function getOpenDayForm({ tags }) {
  return function OpenDayForm({ disabled }) {
    return (
      <>
        <Item
          label="日期"
          name="dateMoment"
          rules={[
            {
              required: true,
              whitespace: true,
              transform: moment => moment.format('YYYY-MM-DD'),
              message: '请选择开放日',
            },
          ]}
        >
          <DatePicker disabled={disabled} format="YYYY-MM-DD" />
        </Item>
        <Item label="时间" name="time" rules={[{ required: true, whitespace: true, message: '请输入开放日时间' }]}>
          <Input disabled={disabled} />
        </Item>
        <Item
          label="体验内容"
          name="tags"
          rules={[{ required: true, whitespace: true, type: 'array', message: '请选择体验内容' }]}
        >
          <Select mode="multiple" disabled={disabled}>
            {tags.map((tag, index) => {
              return (
                <Select.Option key={`open-day-tag-${index}`} value={tag.id}>
                  {tag.name}
                </Select.Option>
              );
            })}
          </Select>
        </Item>
      </>
    );
  };
}

export default class Openday extends React.Component {
  state = { tags: [] };

  componentDidMount() {
    openDay.getOptions().then(options => {
      this.setState({ ...options });
    });
  }

  transformInput = item => {
    item.dateMoment = item.date ? moment(item.date) : null;
  };

  transformOutput = async item => {
    item.date = item.dateMoment.format('YYYY-MM-DD');
  };

  getInitialValues = () => {
    return {
      tags: [1, 2],
    };
  };

  render() {
    const {
      params: { id },
    } = this.props.match;
    const { tags } = this.state;
    return (
      <ItemListContainer
        name="开放日"
        columns={[
          {
            title: '日期',
            key: 'date',
            compare: true,
          },
          { title: '时间', key: 'time', compare: true },
          {
            title: '体验内容',
            key: 'tags',
            compare: true,
            render: value =>
              (value || [])
                .map(id => tags.find(option => option.id === id))
                .filter(option => option)
                .map(option => option.name)
                .join(' / '),
          },
        ]}
        transformInput={this.transformInput}
        transformOutput={this.transformOutput}
        getInitialValues={this.getInitialValues}
        api={openDay}
        intSchoolId={id}
        form={getOpenDayForm({ tags })}
        hideOrder
      />
    );
  }
}
