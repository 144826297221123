import React from 'react';
import Queue from 'p-queue';
import { message } from 'antd';
import { bmapCreateMap, bmapAddressToPoint, bmapCreateMarker } from '../common/baidumap';

export default class BaiduMapView extends React.PureComponent {
  ref = React.createRef();

  queue = new Queue({ concurrency: 1, autoStart: true });

  map = null;

  componentDidMount() {
    const { city } = this.props;
    if (!city) {
      throw new Error('请选择好城市再打开地图');
    }
    this.queue.add(this.initMap);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.address && nextProps.address !== this.props.address) {
      if (this.local) {
        this.local.search(nextProps.address);
      }
    }
  }

  initMap = () => {
    const { address, city } = this.props;
    bmapCreateMap(this.ref.current).then(
      async map => {
        this.map = map;
        if (address) {
          const point = await bmapAddressToPoint(address, city);
          map.centerAndZoom(point, 15);
          const marker = await bmapCreateMarker(point, { title: address });
          map.addOverlay(marker);
        } else {
          map.centerAndZoom(city);
        }
      },
      err => {
        message.error(err.message);
      }
    );
  };

  render() {
    const { width = 640, height = 480 } = this.props;
    return <div ref={this.ref} style={{ width, height }} />;
  }
}
