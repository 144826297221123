import React from 'react';
import { Link } from 'react-router-dom';
import { Table, message, Popconfirm, PageHeader, Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { getInternationalSchoolList, intSchoolSubmitEnabled, editIntSchoolOrder } from '../../services/intschool';
import QrCode from '../../component/qrcode';
import './list.less';
import { Storage } from '../../common/utils';
import { appConfig } from '../../common/config';

const reviewType = [
  { id: true, name: '待审核' },
  { id: false, name: '无需审核' },
];

export default class InternationalSchool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        page: 1,
        pageSize: 20,
      },
      data: [],
      filterOption: {
        keyword: undefined,
        inCoop: undefined,
        enabled: undefined,
        pending: undefined,
        reviewStatus: undefined,
        ...this.props.location.query,
      },
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadPengingData = () => {
    this.setState(
      {
        filterOption: {
          keyword: undefined,
          inCoop: undefined,
          enabled: undefined,
          pending: true,
          reviewStatus: undefined,
        },
      },
      this.loadData
    );
  };

  loadData = filterParams => {
    const { pagination, filterOption } = this.state;
    const params = {
      ...pagination,
      ...filterOption,
      ...filterParams,
    };

    getInternationalSchoolList(params).then(res => {
      this.setState({
        pagination: res.pagination,
        data: res.data,
      });
    });
  };

  // 选择绑定
  selectBindingId = key => id => {
    this.setState({
      [key]: id,
    });
  };

  onModifyIntSchoolEnabled = ({ id, enabled }) => {
    intSchoolSubmitEnabled({ id, enabled: !enabled }).then(res => {
      message.success('设置成功');
      this.loadData();
    });
  };

  onEditOrder = (order, id) => {
    if (!order) {
      message.error('请填写顺序');
      return;
    }
    const value = Number(order);
    if (!Number.isInteger(value) || !(value > 0)) {
      message.error('请填写正确的顺序');
      return;
    }
    editIntSchoolOrder({ id, order }).then(() => {
      message.success('修改成功');
      this.loadData();
    });
  };

  render() {
    const { pagination, data } = this.state;

    return (
      <div className="container-international-school">
        <PageHeader style={{ borderTop: '1px solid #edf0f2', background: 'white' }} title="国际学校列表" />
        <div className="content-white-box">
          <Table
            rowKey="id"
            columns={[
              {
                title: '学校名称',
                key: 'chineseName',
                dataIndex: 'chineseName',
                ellipsis: true,
                render: (value, { id, enabled }) => (
                  <Popover
                    trigger="click"
                    content={
                      enabled ? (
                        <div style={{ textAlign: 'center' }}>
                          <span>手机扫码查看页面</span>
                          <QrCode text={`${appConfig.frontend}/international-school/detail/${id}`} />
                        </div>
                      ) : (
                        '只能够查看已经上线的学校页面'
                      )
                    }
                  >
                    <span className="color-blue6">{value}</span>
                  </Popover>
                ),
              },
              {
                title: '状态',
                width: 100,
                key: 'enabled',
                dataIndex: 'enabled',
                align: 'center',
                render: (enabled, row) => {
                  const display = (
                    <span className={`${enabled ? 'color-green6' : 'color-red6'}`}>
                      {enabled ? '已上线' : '已下线'}
                    </span>
                  );
                  return <Popconfirm>{display}</Popconfirm>;
                },
              },
              {
                title: '审核状态',
                width: '90px',
                key: 'pending',
                dataIndex: 'pending',
                align: 'center',
                render: pending => {
                  const option = reviewType.find(item => item.id === pending);
                  const name = (option && option.name) || pending;
                  const className = pending ? 'color-orange6' : 'color-gray6';
                  return <span className={className}>{name}</span>;
                },
              },
              {
                title: (
                  <div>
                    <span style={{ marginRight: 5 }}>招生官</span>
                    <Popover
                      title="招生官说明"
                      overlayStyle={{ width: 300 }}
                      content={
                        <div>
                          <div>1、招生官一般是指负责学校营销和招生的老师，每个学校仅支持绑定一个招生官；</div>
                          <div>2、招生官需要注册选校帝老师端账号，并通过选校帝运营的认证审核；</div>
                          <div>3、招生官可通过发布动态、回答问题、接受预约等方式，直接与学生/家长互动；</div>
                          <div>4、招生官账号中发布的动态、升学案例，都将同步显示到学校的详情页中。</div>
                        </div>
                      }
                    >
                      <QuestionCircleOutlined />
                    </Popover>
                  </div>
                ),
                width: '105px',
                key: 'admin',
                dataIndex: 'admin',
                align: 'center',
                render: admin => {
                  return admin && admin.name;
                },
              },
              {
                title: '操作',
                width: '100px',
                key: 'handle',
                align: 'center',
                render: (row = {}) => {
                  return (
                    <Link
                      className="color-blue6"
                      to={`/detail/${row.id}`}
                      onClick={() => Storage.setItem('schoolName', row.chineseName)}
                    >
                      管理
                    </Link>
                  );
                },
              },
            ]}
            dataSource={data}
            bordered={true}
            pagination={{
              pageSize: pagination.pageSize,
              current: pagination.page,
              total: pagination.count,
              onChange: (page, pageSize) => this.loadData({ page, pageSize }),
            }}
          />
        </div>
      </div>
    );
  }
}
