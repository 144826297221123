/**
 * @typedef {object} AppConfig
 * @property {string} api
 * @property {string} frontend
 * @property {string} consultantAdmin
 * @property {string} cdnUpload
 * @property {object} baidumap
 * @property {string} baidumap.ak
 */

/** @type {AppConfig} */
export const appConfig = window.config;
