import axios from '../common/axios';

// 获取中国省份城市
export const getChinaProvinceCityList = () => axios.get('/cities/china');

export const getSchoolList = ({ keyword }) => {
  return axios.get('/schools', { keyword });
};

// 获取学校列表
export const getInternationalSchoolList = ({
  page = 1,
  pageSize = 20,
  keyword,
  inCoop,
  enabled,
  reviewStatus,
  pending,
} = {}) => {
  return axios.get('/intschool/admin/list', {
    page,
    pageSize,
    keyword,
    inCoop,
    enabled,
    reviewStatus,
    pending,
  });
};

// 设置绑定人员
export const modifyIntSchoolBindingsAdmin = async ({ id, inCoop, consultantId, adminId }) => {
  return axios.put('/intschool/admin/item/bindings', { id, inCoop, consultantId, adminId });
};

// 获取可绑定管理员列表
export const getSchoolBindingsAdminList = () => {
  return axios.get('/intschool/admin/item/bindings/admins');
};

// 学校提交审核
export const intSchoolSubmitItem = async data => {
  return axios.post('/intschool/admin/item/submit', data);
};

// 学校上下线
export const intSchoolSubmitEnabled = async ({ id, enabled }) => {
  return axios.put('/intschool/admin/item/enabled', { id, enabled });
};

// 获取国际学校基本数据
export const getIntSchoolItem = async ({ id }) => {
  return axios.get('/intschool/admin/item', {
    id,
  });
};

// 创建国际学校
export const createIntSchoolItem = async params => {
  return axios.post('/intschool/admin/item', params);
};

// 编辑国际学校详情部分数据（除升学数据的部分）
export const editIntSchoolItemGeneral = async params => {
  return axios.put('/intschool/admin/item/general', params);
};

// 编辑国际学校详情升学数据
export const editIntSchoolItemGraduate = async params => {
  return axios.put('/intschool/admin/item/graduate', params);
};

// 后台获取国际学校基本信息相关选项数据
export const getIntSchoolOptions = async () => {
  return axios.get('/intschool/admin/item/options');
};

// 获取导师列表
export const getThirdTeacherList = async () => {
  return axios.get('/thirdparty/consultant/admin/filters', { appointmentEnabled: true });
};

// 后台审核国际学校基本数据
export const reviewIntSchool = async ({ id, accept }) => {
  return axios.post('/intschool/admin/item/review', { id, accept });
};

// 后台审核国际学校基本数据
export const editIntSchoolOrder = async ({ id, order }) => {
  return axios.put('/intschool/admin/item/order', { id, order });
};

// 社团管理
export const community = {
  /**
   * 获取社团列表
   */
  getList: async ({ page = 1, pageSize = 20, intSchoolId }) => {
    return axios.get('/intschool/admin/community/list', { page, pageSize, intSchoolId });
  },

  /**
   * 获取社团详情
   */
  getItem: async ({ id, intSchoolId }) => {
    return axios.get('/intschool/admin/community', { id, intSchoolId });
  },

  /**
   * 添加社团
   */
  add: ({ intSchoolId, name, coverUrl, title, content }) => {
    return axios.post('/intschool/admin/community', { intSchoolId, name, coverUrl, title, content });
  },

  /**
   * 编辑社团
   */
  modify: ({ id, intSchoolId, name, coverUrl, title, content }) => {
    return axios.put('/intschool/admin/community', { id, intSchoolId, name, coverUrl, title, content });
  },

  /**
   *  提交审核国际学校社团
   */
  submit: ({ ids, intSchoolId }) => {
    return axios.post('/intschool/admin/community/submit', { ids, intSchoolId });
  },

  /**
   * 审核社团
   */
  review: ({ ids, intSchoolId, accept }) => {
    return axios.post('/intschool/admin/community/review', { ids, intSchoolId, accept });
  },

  /**
   * 上下线社团
   */
  enabled: ({ id, intSchoolId, enabled }) => {
    return axios.put('/intschool/admin/community/enabled', { id, intSchoolId, enabled });
  },

  /**
   * 调整社团顺序
   */
  order: ({ id, intSchoolId, order }) => {
    return axios.put('/intschool/admin/community/order', { id, intSchoolId, order });
  },

  /**
   * 删除社团
   */
  delete: ({ id, intSchoolId }) => {
    return axios.delete('/intschool/admin/community', { params: { id, intSchoolId } });
  },
};

// 招生项目 管理
export const program = {
  /**
   * 获取招生项目列表
   */
  getList: ({ intSchoolId, page, pageSize }) => {
    return axios.get('/intschool/admin/program/list', { intSchoolId, page, pageSize });
  },

  /**
   * 获取招生项目详情
   */
  getItem: ({ id, intSchoolId }) => {
    return axios.get('/intschool/admin/program', { id, intSchoolId });
  },

  /**
   * 获取招生项目选项
   */
  getOptions: () => {
    return axios.get('/intschool/admin/program/options');
  },

  /**
   * 添加招生项目
   */
  add: data => {
    return axios.post('/intschool/admin/program', data);
  },

  /**
   * 编辑招生项目
   */
  modify: data => {
    return axios.put('/intschool/admin/program', data);
  },

  /**
   * 提审招生项目
   */
  submit: ({ ids, intSchoolId }) => {
    return axios.post('/intschool/admin/program/submit', { ids, intSchoolId });
  },

  /**
   * 审核招生项目
   */
  review: ({ ids, intSchoolId, accept }) => {
    return axios.post('/intschool/admin/program/review', { ids, intSchoolId, accept });
  },

  /**
   * 上下线招生项目
   */
  enabled: ({ id, intSchoolId, enabled }) => {
    return axios.put('/intschool/admin/program/enabled', { id, intSchoolId, enabled });
  },

  /**
   * 调整招生项目顺序
   */
  order: ({ id, intSchoolId, order }) => {
    return axios.put('/intschool/admin/program/order', { id, intSchoolId, order });
  },

  /**
   * 删除招生项目
   */
  delete: ({ id, intSchoolId }) => {
    return axios.delete('/intschool/admin/program', { params: { id, intSchoolId } });
  },
};

/**
 * 师资力量 管理
 */
export const faculty = {
  /**
   * 获取教师列表
   */
  getList: ({ intSchoolId, page, pageSize }) => {
    return axios.get('/intschool/admin/teacher/list', { intSchoolId, page, pageSize });
  },

  /**
   * 添加教师
   */
  add: ({ intSchoolId, name, avatarUrl, introduction }) => {
    return axios.post('/intschool/admin/teacher', { intSchoolId, name, avatarUrl, introduction });
  },

  /**
   * 编辑教师
   */
  modify: ({ id, intSchoolId, name, avatarUrl, introduction }) => {
    return axios.put('/intschool/admin/teacher', { id, intSchoolId, name, avatarUrl, introduction });
  },

  /**
   * 提审教师
   */
  submit: ({ ids, intSchoolId }) => {
    return axios.post('/intschool/admin/teacher/submit', { ids, intSchoolId });
  },

  /**
   * 审核教师
   */
  review: ({ ids, intSchoolId, accept }) => {
    return axios.post('/intschool/admin/teacher/review', { ids, intSchoolId, accept });
  },

  /**
   * 上下线教师
   */
  enabled: ({ id, intSchoolId, enabled }) => {
    return axios.put('/intschool/admin/teacher/enabled', { id, intSchoolId, enabled });
  },

  /**
   * 调整教师顺序
   */
  order: ({ id, intSchoolId, order }) => {
    return axios.put('/intschool/admin/teacher/order', { id, intSchoolId, order });
  },

  /**
   * 删除教师
   */
  delete: ({ id, intSchoolId }) => {
    return axios.delete('/intschool/admin/teacher', { params: { id, intSchoolId } });
  },
};

/**
 * 学生 管理
 */
export const student = {
  /**
   * 获取优秀毕业生列表
   */
  getList: ({ intSchoolId, page, pageSize }) => {
    return axios.get('/intschool/admin/student/list', { intSchoolId, page, pageSize });
  },

  /**
   * 获取优秀毕业生详情
   */
  getItem: ({ id, intSchoolId }) => {
    return axios.get('/intschool/admin/student', { intSchoolId, id });
  },

  /**
   * 添加优秀毕业生
   */
  add: ({ intSchoolId, title, coverUrl, content }) => {
    return axios.post('/intschool/admin/student', { intSchoolId, title, coverUrl, content });
  },

  /**
   * 编辑优秀毕业生
   */
  modify: ({ intSchoolId, title, coverUrl, content, id }) => {
    return axios.put('/intschool/admin/student', { intSchoolId, title, coverUrl, content, id });
  },

  /**
   * 提审优秀毕业生
   */
  submit: ({ ids, intSchoolId }) => {
    return axios.post('/intschool/admin/student/submit', { ids, intSchoolId });
  },

  /**
   * 审核优秀毕业生
   */
  review: ({ ids, intSchoolId, accept }) => {
    return axios.post('/intschool/admin/student/review', { ids, intSchoolId, accept });
  },

  /**
   * 上下线优秀毕业生
   */
  enabled: ({ id, intSchoolId, enabled }) => {
    return axios.put('/intschool/admin/student/enabled', { id, intSchoolId, enabled });
  },

  /**
   * 调整优秀毕业生顺序
   */
  order: ({ id, intSchoolId, order }) => {
    return axios.put('/intschool/admin/student/order', { id, intSchoolId, order });
  },

  /**
   * 删除优秀毕业生
   */
  delete: ({ id, intSchoolId }) => {
    return axios.delete('/intschool/admin/student', { params: { id, intSchoolId } });
  },
};

/**
 * 文章管理
 */
export const article = {
  /**
   * 获取文章列表
   */
  getList: ({ intSchoolId, page, pageSize }) => {
    return axios.get('/intschool/admin/article/list', { intSchoolId, page, pageSize });
  },

  /**
   * 获取文章详情
   */
  getItem: ({ id, intSchoolId }) => {
    return axios.get('/intschool/admin/article', { intSchoolId, id });
  },

  /**
   * 添加文章
   */
  add: ({ intSchoolId, title, coverUrl, content }) => {
    return axios.post('/intschool/admin/article', { intSchoolId, title, coverUrl, content });
  },

  /**
   * 编辑文章
   */
  modify: ({ id, intSchoolId, title, coverUrl, content }) => {
    return axios.put('/intschool/admin/article', { id, intSchoolId, title, coverUrl, content });
  },

  /**
   * 提审文章
   */
  submit: ({ ids, intSchoolId }) => {
    return axios.post('/intschool/admin/article/submit', { ids, intSchoolId });
  },

  /**
   * 审核文章
   */
  review: ({ ids, intSchoolId, accept }) => {
    return axios.post('/intschool/admin/article/review', { ids, intSchoolId, accept });
  },

  /**
   * 上下线文章
   */
  enabled: ({ id, intSchoolId, enabled }) => {
    return axios.put('/intschool/admin/article/enabled', { id, intSchoolId, enabled });
  },

  /**
   * 调整文章顺序
   */
  order: ({ id, intSchoolId, order }) => {
    return axios.put('/intschool/admin/article/order', { id, intSchoolId, order });
  },

  /**
   * 删除文章
   */
  delete: ({ id, intSchoolId }) => {
    return axios.delete('/intschool/admin/article', { params: { id, intSchoolId } });
  },
};

/**
 * 常见问题问答 管理
 */
export const faq = {
  /**
   * 获取问答列表
   */
  getList: ({ page, pageSize, intSchoolId }) => {
    return axios.get('/intschool/admin/faq/list', { page, pageSize, intSchoolId });
  },

  /**
   * 添加常见问答
   */
  add: ({ intSchoolId, question, answer }) => {
    return axios.post('/intschool/admin/faq', { intSchoolId, question, answer });
  },

  /**
   * 编辑常见问答
   */
  modify: ({ id, intSchoolId, question, answer }) => {
    return axios.put('/intschool/admin/faq', { id, intSchoolId, question, answer });
  },

  // 提审常见问答
  submit: ({ ids, intSchoolId }) => {
    return axios.post('/intschool/admin/faq/submit', { ids, intSchoolId });
  },

  // 审核常见问答
  review: ({ ids, intSchoolId, accept }) => {
    return axios.post('/intschool/admin/faq/review', { ids, intSchoolId, accept });
  },

  /**
   * 上下线常见问答
   */
  enabled: ({ id, intSchoolId, enabled }) => {
    return axios.put('/intschool/admin/faq/enabled', { id, intSchoolId, enabled });
  },

  /**
   * 调整常见问答顺序
   */
  order: ({ id, intSchoolId, order }) => {
    return axios.put('/intschool/admin/faq/order', { id, intSchoolId, order });
  },

  /**
   * 删除常见问答
   */
  delete: ({ id, intSchoolId }) => {
    return axios.delete('/intschool/admin/faq', { params: { id, intSchoolId } });
  },
};

/**
 * 点评管理
 */
export const comment = {
  /**
   * 获取国际学校点评列表
   */
  getList: ({ page, pageSize, intSchoolId }) => {
    return axios.get('/intschool/admin/comment/list', { page, pageSize, intSchoolId });
  },

  /**
   * 添加国际学校点评
   */
  add: ({ intSchoolId, category, content }) => {
    return axios.post('/intschool/admin/comment', { intSchoolId, category, content });
  },

  /**
   * 修改国际学校点评
   */
  modify: ({ id, intSchoolId, category, content }) => {
    return axios.put('/intschool/admin/comment', { id, intSchoolId, category, content });
  },

  /**
   * 提审国际学校点评
   */
  submit: ({ intSchoolId, ids }) => {
    return axios.post('/intschool/admin/comment/submit', { intSchoolId, ids });
  },

  /**
   * 审核国际学校点评
   */
  review: ({ intSchoolId, ids, accept }) => {
    return axios.post('/intschool/admin/comment/review', { intSchoolId, ids, accept });
  },

  /**
   * 上下线国际学校点评
   */
  enabled: ({ intSchoolId, id, enabled }) => {
    return axios.put('/intschool/admin/comment/enabled', { intSchoolId, id, enabled });
  },

  /**
   * 调整点评顺序
   */
  order: ({ id, intSchoolId, order }) => {
    return axios.put('/intschool/admin/comment/order', { id, intSchoolId, order });
  },

  /**
   * 删除国际学校点评
   */
  delete: ({ intSchoolId, id }) => {
    return axios.delete('/intschool/admin/comment', { params: { intSchoolId, id } });
  },
};

/**
 * 图片管理
 */
export const photo = {
  /**
   * 获取照片列表
   */
  getList: ({ intSchoolId, page, pageSize }) => {
    return axios.get('/intschool/admin/photo/list', { intSchoolId, page, pageSize });
  },

  /**
   * 添加照片
   */
  add: ({ intSchoolId, imageUrl, width, height, text }) => {
    return axios.post('/intschool/admin/photo', { intSchoolId, imageUrl, width, height, text });
  },

  /**
   * 编辑照片
   */
  modify: ({ id, intSchoolId, imageUrl, width, height, text }) => {
    return axios.put('/intschool/admin/photo', { id, intSchoolId, imageUrl, width, height, text });
  },

  /**
   * 提审照片
   */
  submit: ({ ids, intSchoolId }) => {
    return axios.post('/intschool/admin/photo/submit', { ids, intSchoolId });
  },

  /**
   * 审核照片
   */
  review: ({ ids, intSchoolId, accept }) => {
    return axios.post('/intschool/admin/photo/review', { ids, intSchoolId, accept });
  },

  /**
   * 上下线照片
   */
  enabled: ({ id, intSchoolId, enabled }) => {
    return axios.put('/intschool/admin/photo/enabled', { id, intSchoolId, enabled });
  },

  /**
   * 调整照片顺序
   */
  order: ({ id, intSchoolId, order }) => {
    return axios.put('/intschool/admin/photo/order', { id, intSchoolId, order });
  },

  /**
   * 删除照片
   */
  delete: ({ id, intSchoolId }) => {
    return axios.delete('/intschool/admin/photo', { params: { id, intSchoolId } });
  },
};

/**
 * 开放日管理
 */
export const openDay = {
  /**
   * 获取开放日列表
   */
  getList: ({ id, page, pageSize, intSchoolId }) => {
    return axios.get('/intschool/admin/openday/list', { id, intSchoolId, page, pageSize });
  },

  /**
   * 添加开放日
   */
  add: ({ intSchoolId, date, time, tags }) => {
    return axios.post('/intschool/admin/openday', { intSchoolId, date, time, tags });
  },

  /**
   * 编辑开放日
   */
  modify: ({ id, intSchoolId, date, time, tags }) => {
    return axios.put('/intschool/admin/openday', { id, intSchoolId, date, time, tags });
  },

  /**
   * 提审开放日
   */
  submit: ({ intSchoolId, ids }) => {
    return axios.post('/intschool/admin/openday/submit', { intSchoolId, ids });
  },

  /**
   * 审核开放日
   */
  review: ({ intSchoolId, ids, accept }) => {
    return axios.post('/intschool/admin/openday/review', { intSchoolId, ids, accept });
  },

  /**
   * 上下线开放日
   */
  enabled: ({ intSchoolId, id, enabled }) => {
    return axios.put('/intschool/admin/openday/enabled', { intSchoolId, id, enabled });
  },

  /**
   * 调整开放日顺序
   */
  order: ({ id, intSchoolId, order }) => {
    return axios.put('/intschool/admin/openday/order', { id, intSchoolId, order });
  },

  /**
   * 删除开放日
   */
  delete: ({ intSchoolId, id }) => {
    return axios.delete('/intschool/admin/openday', { params: { intSchoolId, id } });
  },

  /**
   * 获取开放日选项
   */
  getOptions: () => {
    return axios.get('/intschool/admin/openday/options');
  },
};
