import * as React from 'react';
import { login } from '../services/user';
import constant from '../common/constant';
import { Storage } from '../common/utils';
import { message } from 'antd';

const LoginContext = React.createContext();

// 本地缓存获取用户信息
const userInfo = Storage.getItem(constant.storageKey.USERINFO);
const ticket = Storage.getItem(constant.storageKey.TICKET);
const status = Storage.getItem(constant.storageKey.STATUS);

// 初始化数据
const initialState = {
  status,
  userInfo,
  isSigned: Boolean(ticket),
};

// 纯函数
const reducer = (state, action) => {
  switch (action.type) {
    case '_login':
      return { ...state, ...action.payload };
    case '_logout':
      return { ...state, ...action.payload };
    case 'updateStatusAndUserInfo':
      return { ...state, ...action.payload };
    default:
      throw new Error('登录操作出错', action);
  }
};

function LoginContextProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  // 自定义异步处理函数
  const asyncDispatch = React.useCallback(async action => {
    switch (action.type) {
      case 'login':
        try {
          const res = await login(action.payload);
          if (res) {
            Storage.setItem(constant.storageKey.TICKET, res.ticket);
            Storage.setItem(constant.storageKey.STATUS, res.admin.id);
            Storage.setItem(constant.storageKey.USERINFO, res.admin);
            dispatch({
              type: '_login',
              payload: {
                isSigned: Boolean(res.ticket),
                status: res.admin.id,
                userInfo: res.admin,
              },
            });
          }
        } catch (error) {
          message.error(error.message);
        }
        break;
      case 'logout':
        // todo: 服务器登出操作，清除本地登录凭证
        Storage.removeItem(constant.storageKey.TICKET);
        Storage.removeItem(constant.storageKey.STATUS);
        Storage.removeItem(constant.storageKey.USERINFO);
        dispatch({
          type: '_logout',
          payload: {
            isSigned: false,
            status: undefined,
            userInfo: undefined, // have to be undefined, can`t be null
          },
        });
        break;
      case 'updateStatusAndUserInfo':
        Storage.setItem(constant.storageKey.STATUS, action.payload.status);
        Storage.setItem(constant.storageKey.USERINFO, action.payload.userInfo);
        dispatch(action);
        break;
      default:
        dispatch(action);
    }
  }, []);

  return <LoginContext.Provider value={{ state, dispatch: asyncDispatch }}>{children}</LoginContext.Provider>;
}

const LoginContextConsumer = LoginContext.Consumer;

export { LoginContext, LoginContextProvider, LoginContextConsumer };
