import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { LoginContext } from '../../context/loginContext';
import { sendCaptcha, getCaptchaImage } from '../../services/user';
import './login.less';
import bg from '../../assets/images/pc_bg.png';
import { appConfig } from '../../common/config';

const { Item } = Form;

function Login(props) {
  const [form] = Form.useForm();
  const CAPTCHA_TOTAL_COUNT = 30; // 验证码倒计时时间, 秒
  const { dispatch } = React.useContext(LoginContext);
  let [captchaImage, setCaptchaImage] = React.useState({});
  let [count, setCount] = React.useState(CAPTCHA_TOTAL_COUNT);
  const [currentIndex, setCurrentIndex] = React.useState(1);
  let timer = null;

  // 倒计时
  function countdown() {
    let currentCount = CAPTCHA_TOTAL_COUNT;
    clearTimer();
    timer = setInterval(() => {
      currentCount--;
      if (currentCount <= 0) {
        clearTimer();
        setCount(CAPTCHA_TOTAL_COUNT);
      } else {
        setCount(currentCount);
      }
    }, 1000);
  }
  function clearTimer() {
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
  }

  // 获取图形验证码
  const getCaptchaImageAsync = React.useCallback(async () => {
    setCaptchaImage(await getCaptchaImage());
  }, []);
  React.useEffect(() => {
    getCaptchaImageAsync();
  }, [getCaptchaImageAsync]);

  // 更新图形验证码
  function updateCaptchaImage() {
    getCaptchaImageAsync();
  }

  // 发送短信验证码
  function sendSmsCaptcha() {
    if (count !== CAPTCHA_TOTAL_COUNT) {
      return;
    }
    form.validateFields(['imageCaptcha', 'account']).then(async ({ account, imageCaptcha }) => {
      try {
        await sendCaptcha({
          phone: account,
          captcha: imageCaptcha,
          nonce: captchaImage.nonce,
        });
        countdown();
        message.success('验证码发送成功，请留意手机短信');
      } catch (error) {
        console.log(error); // eslint-disable-line
        message.error(error.message || '验证码发送失败');
      }
    });
  }

  // 提交登录表单
  function submit(values) {
    dispatch({
      type: 'login',
      payload: {
        account: values.account,
        captcha: values.smsCaptcha,
        nonce: captchaImage.nonce,
      },
    });
  }

  return (
    <div
      className="login-container"
      style={{
        backgroundImage: `url(${bg})`,
      }}
    >
      <div className="login-box">
        <div className="left-box">
          <div className="xxd-name-box">
            <div className="xxd-name">
              <svg className="logo" data-name="图层 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 290 90">
                <g fill-rule="evenodd">
                  <path d="M153.34,36.45a1.7,1.7,0,0,0,1.49-1l4.43-13.87a.73.73,0,0,0-.73-1H147.2a1.7,1.7,0,0,0-1.49,1l-4.43,13.87a.73.73,0,0,0,.73,1Z" />
                  <path d="M176.67,36.45a1.7,1.7,0,0,1-1.49-1l-4.43-13.87a.73.73,0,0,1,.73-1h11.33a1.7,1.7,0,0,1,1.49,1l4.43,13.87a.73.73,0,0,1-.73,1Z" />
                  <path d="M188,89.54a.61.61,0,0,0,.58-1L173.32,60.86a2.29,2.29,0,0,1,0-1.95L185,40a.91.91,0,0,0-.65-1.23H172.51a2.05,2.05,0,0,0-1.63,1l-5.93,9.79c-.29.54-.76.54-1.05,0l-5.19-9.78a2.05,2.05,0,0,0-1.64-1H145a.61.61,0,0,0-.58,1l11.09,19.19a2.29,2.29,0,0,1,0,1.95L140,88.57a.61.61,0,0,0,.58,1h12.06a2.05,2.05,0,0,0,1.63-1l9.58-17.36c.29-.54.76-.54,1.05,0l9.32,17.36a2.05,2.05,0,0,0,1.64,1Z" />
                  <path d="M143.39,58.72a.73.73,0,0,0,.73-1L139,40.23a1.7,1.7,0,0,0-1.49-1h-7.13a1.11,1.11,0,0,1-1.11-1.11V26.55a1.11,1.11,0,0,1,1.11-1.11h7.75a1.11,1.11,0,0,0,1.11-1.11V19.26a1.11,1.11,0,0,1,1.11-1.11h47.48A1.11,1.11,0,0,0,189,17V10.11A1.11,1.11,0,0,0,187.87,9h-47.6a1.11,1.11,0,0,0-1.11,1.11v5.08a1.11,1.11,0,0,1-1.11,1.11h-7.63a1.11,1.11,0,0,1-1.11-1.11V1.11A1.11,1.11,0,0,0,128.21,0H116.88a1.11,1.11,0,0,0-1.11,1.11V15.19a1.11,1.11,0,0,1-1.11,1.11H100.78a1.11,1.11,0,0,0-1.11,1.11v6.93a1.11,1.11,0,0,0,1.11,1.11h13.89a1.11,1.11,0,0,1,1.11,1.11v2a1.11,1.11,0,0,1-1.11,1.11h-5.49a1.37,1.37,0,0,0-1.3,1.09L99.49,79.57a.9.9,0,0,0,.92,1.09H111a1.37,1.37,0,0,0,1.3-1.09l3.27-21.9c.11-.6.19-.59.19,0V88.44a1.11,1.11,0,0,0,1.11,1.11h11.33a1.11,1.11,0,0,0,1.11-1.11V53.95c0-.61.17-.64.38-.07l.87,3.8a1.7,1.7,0,0,0,1.49,1Z" />
                  <path d="M158.1,5.13a1.7,1.7,0,0,0,1.49,1h11.33a.73.73,0,0,0,.73-1L170.16,1a1.7,1.7,0,0,0-1.49-1H157.35a.73.73,0,0,0-.73,1Z" />
                  <path d="M39.2,47.7a1.52,1.52,0,0,0-1.4,1l-8.35,26.6a.79.79,0,0,0,.82,1H41.61a1.52,1.52,0,0,0,1.4-1l8.35-26.6a.79.79,0,0,0-.82-1Z" />
                  <path d="M66.79,33.64a1.11,1.11,0,0,1-1.11-1.11V22.46a1.11,1.11,0,0,1,1.11-1.11H82.51a1.11,1.11,0,0,0,1.11-1.11V13.31a1.11,1.11,0,0,0-1.11-1.11H66.79a1.11,1.11,0,0,1-1.11-1.11V1.29A1.11,1.11,0,0,0,64.57.19H53.24a1.11,1.11,0,0,0-1.11,1.11v9.8A1.11,1.11,0,0,1,51,12.2H46.71a.73.73,0,0,1-.73-1l2.43-6.64a.73.73,0,0,0-.73-1H36.35a1.7,1.7,0,0,0-1.49,1L27.13,26a.73.73,0,0,0,.73,1H39.19a1.7,1.7,0,0,0,1.49-1l1.22-3.58a1.7,1.7,0,0,1,1.49-1H51a1.11,1.11,0,0,1,1.11,1.11V32.53A1.11,1.11,0,0,1,51,33.64H27.52a1.11,1.11,0,0,0-1.11,1.11v6.93a1.11,1.11,0,0,0,1.11,1.11H60.27a1.11,1.11,0,0,1,1.11,1.11V75.28a1.11,1.11,0,0,0,1.11,1.11h24a1.11,1.11,0,0,0,1.11-1.11V68.35a1.11,1.11,0,0,0-1.11-1.11H76a1.11,1.11,0,0,1-1.11-1.11V43.89A1.11,1.11,0,0,1,76,42.78H86.35a1.11,1.11,0,0,0,1.11-1.11V34.74a1.11,1.11,0,0,0-1.11-1.11Z" />
                  <path d="M238.64,5.13a1.7,1.7,0,0,0,1.49,1h11.33a.73.73,0,0,0,.73-1L250.7,1a1.7,1.7,0,0,0-1.49-1H237.88a.73.73,0,0,0-.73,1Z" />
                  <path d="M288.2,30.63H201.15A1.11,1.11,0,0,0,200,31.74V44.24a1.11,1.11,0,0,0,1.11,1.11h11.33a1.11,1.11,0,0,0,1.11-1.11V40.88a1.11,1.11,0,0,1,1.11-1.11h60a1.11,1.11,0,0,1,1.11,1.11v3.36a1.11,1.11,0,0,0,1.11,1.11H288.2a1.11,1.11,0,0,0,1.11-1.11V31.74A1.11,1.11,0,0,0,288.2,30.63Z" />
                  <path d="M215.14,31.55h11.33a.73.73,0,0,0,.73-1L223.73,21a1.7,1.7,0,0,0-1.49-1H210.91a.73.73,0,0,0-.73,1l3.47,9.54A1.7,1.7,0,0,0,215.14,31.55Z" />
                  <path d="M283.26,48.25h-31a1.11,1.11,0,0,1-1.11-1.11v-3A1.11,1.11,0,0,0,250,43.08H238.71a1.11,1.11,0,0,0-1.11,1.11v3a1.11,1.11,0,0,1-1.11,1.11H206.08A1.11,1.11,0,0,0,205,49.36V83.09a1.11,1.11,0,0,0,1.11,1.11h11.33a1.11,1.11,0,0,0,1.11-1.11V58.51a1.11,1.11,0,0,1,1.11-1.11h16.86a1.11,1.11,0,0,1,1.11,1.11V88.31a1.11,1.11,0,0,0,1.11,1.11H250a1.11,1.11,0,0,0,1.11-1.11V58.51a1.11,1.11,0,0,1,1.11-1.11h17.46a1.11,1.11,0,0,1,1.11,1.11V73.95a1.11,1.11,0,0,1-1.11,1.11h-.47a1.11,1.11,0,0,0-1.11,1.11v6.93a1.11,1.11,0,0,0,1.11,1.11h7.17a7.23,7.23,0,0,0,4-1.08,8.08,8.08,0,0,0,2.91-3,8.5,8.5,0,0,0,1.1-4.07h0V49.36A1.11,1.11,0,0,0,283.26,48.25Z" />
                  <path d="M274.21,31.55a1.7,1.7,0,0,0,1.49-1L279.17,21a.73.73,0,0,0-.73-1H267.11a1.7,1.7,0,0,0-1.49,1l-3.47,9.54a.73.73,0,0,0,.73,1Z" />
                  <rect x="200.04" y="8.59" width="89.27" height="9.15" rx="1.11" ry="1.11" />
                  <path d="M6.86,15.65a7.82,7.82,0,0,0,4,1.1,7.22,7.22,0,0,0,4-1.08,8.08,8.08,0,0,0,2.91-3,8.5,8.5,0,0,0,1.1-4.07,8.29,8.29,0,0,0-1.1-4.11,7.62,7.62,0,0,0-3-3A7.92,7.92,0,0,0,10.88.43,8.52,8.52,0,0,0,7,1.51a7.57,7.57,0,0,0-3,3,7.92,7.92,0,0,0-1.1,4.11,8.27,8.27,0,0,0,1.08,4A7.86,7.86,0,0,0,6.86,15.65ZM90.17,80.49H21.65a1,1,0,0,1-1-.81V64.44h0l-.05-40.58a1,1,0,0,0-1-1H1.34a1,1,0,0,0-1,1v6.81a1,1,0,0,0,1,1H6.4a1,1,0,0,1,1,1V79.3c0,.61-.31,1.11-.92,1.11h-1a1.11,1.11,0,0,0-1.11,1.11v6.93a1.11,1.11,0,0,0,1.11,1.11h5.39a7.23,7.23,0,0,0,4-1.08A8.11,8.11,0,0,0,17,86.61a20.89,20.89,0,0,0,1.74,2.11,5.14,5.14,0,0,0,2.81.87H90.17a1,1,0,0,0,1-1v-7A1,1,0,0,0,90.17,80.49Z" />
                </g>
              </svg>
            </div>
            <div className="desc">赋能国际学校</div>
          </div>
          <div className="info">
            <div
              className={`item-box intschool-box ${currentIndex === 1 ? 'active' : ''}`}
              onMouseEnter={() => setCurrentIndex(1)}
            >
              <div className="title">国际学校管理台（PC）</div>
              <div className="content-text">
                <div className="text-item">{`-  更新学校信息`}</div>
                <div className="text-item">{`-  查看学校曝光数据`}</div>
              </div>
            </div>
            <div
              className={`item-box consultant-box ${currentIndex === 2 ? 'active' : ''}`}
              onMouseEnter={() => setCurrentIndex(2)}
            >
              <div className="title">
                老师管理台（PC&APP）
                <a
                  href={appConfig.consultantAdmin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="entry-consultant"
                >
                  进入
                </a>
              </div>
              <div className="content-text">
                <div className="text-item">{`-  管理学校招生官的信息`}</div>
                <div className="text-item">{`-  发布动态/升学案例等，增加曝光`}</div>
                <div className="text-item">{`-  回答问题、承接预约，直接与客户沟通`}</div>
              </div>
            </div>
          </div>
        </div>
        <Form onFinish={submit} form={form} className="form">
          <h1>国际学校管理平台</h1>
          <div className="form-item-container">
            <Item
              name="account"
              rules={[
                {
                  required: true,
                  message: '请输入手机号码!',
                },
                {
                  pattern: /^1\d{10}$/,
                  message: '手机号码格式错误!',
                },
              ]}
            >
              <Input placeholder="填写11位的手机号码" />
            </Item>
            <Item
              name="imageCaptcha"
              rules={[
                {
                  required: true,
                  message: '请输入右侧的图形验证码!',
                },
              ]}
            >
              <div className="item">
                <Input placeholder="图形验证码" />
                <div
                  className="captcha-box"
                  dangerouslySetInnerHTML={{ __html: captchaImage.image || '' }}
                  onClick={updateCaptchaImage}
                />
              </div>
            </Item>
            <Item
              name="smsCaptcha"
              rules={[
                {
                  required: true,
                  message: '请输入短信验证码!',
                },
              ]}
            >
              <div className="item">
                <Input placeholder="短信验证码" />
                <div className="send-btn" onClick={() => sendSmsCaptcha()}>
                  {count === CAPTCHA_TOTAL_COUNT ? '发送验证码' : `${count}秒`}
                </div>
              </div>
            </Item>
            <Item>
              <Button className="submit-btn" type="primary" htmlType="submit">
                登录
              </Button>
            </Item>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Login;
