import React from 'react';
import { Modal } from 'antd';

export default function ImagePreview({ src }) {
  return (
    <div
      style={{
        width: 100,
        height: 100,
        padding: 8,
        backgroundColor: '#ffffff',
        border: '1px solid #d8d8d8',
        borderRadius: 2,
      }}
      onClick={() => {
        if (src) {
          Modal.info({
            title: '图片预览',
            icon: false,
            width: '640px',
            content: <img src={src} alt="" style={{ display: 'block', width: '100%' }} />,
            maskClosable: true,
          });
        }
      }}
    >
      <img src={src} alt="" style={{ display: 'block', width: '100%', objectFit: 'cover', height: '100%' }} />
    </div>
  );
}
