/* eslint-disable max-classes-per-file */
import React from 'react';
import { Input, Form, Popover } from 'antd';
import FormImage from '../../component/upload-image';
import RichText from '../../component/rich-text';
import Qrcode from '../../component/qrcode';
import { community } from '../../services/intschool';
import ItemListContainer from './component/item_list_container';
import Time from '../../component/time';
import { appConfig } from '../../common/config';

const { Item } = Form;

function CommunityForm({ disabled }) {
  return (
    <>
      <Item label="社团封面" name="coverUrl" rules={[{ required: true, whitespace: true, message: '请输入社团封面' }]}>
        <FormImage disabled={disabled} preset="common-image" max={1} />
      </Item>
      <Item label="社团名称" name="name" rules={[{ required: true, whitespace: true, message: '请输入社团名称' }]}>
        <Input disabled={disabled} />
      </Item>
      <Item label="介绍标题" name="title" rules={[{ required: true, whitespace: true, message: '请输入社团标题' }]}>
        <Input disabled={disabled} />
      </Item>
      <Item
        label="介绍正文"
        name="content"
        rules={[
          {
            required: true,
            validator: (rule, value) =>
              // eslint-disable-next-line prefer-promise-reject-errors
              RichText.isEmpty(value) ? Promise.reject('请输入介绍正文') : Promise.resolve(),
          },
        ]}
      >
        <RichText disabled={disabled} />
      </Item>
    </>
  );
}
export default function FacultysList({
  match: {
    params: { id },
  },
}) {
  return (
    <ItemListContainer
      name="社团"
      columns={[
        { width: '150px', title: '社团名称', key: 'name', compare: true },
        {
          title: '介绍标题',
          key: 'title',
          compare: true,
          render: (value, { id, isReviewData, pvcode = '' }) => {
            if (!isReviewData) {
              return value;
            }
            return (
              <Popover
                trigger="click"
                content={
                  <div style={{ textAlign: 'center' }}>
                    <span>手机扫码查看页面</span>
                    <Qrcode
                      text={`${appConfig.frontend}/international-school/community/item/${id}?previewCode=${pvcode}`}
                    />
                  </div>
                }
              >
                <span className="color-blue6">{value}</span>
              </Popover>
            );
          },
        },
        {
          title: '创建时间',
          key: 'createTime',
          width: '120px',
          render: createTime => <Time time={createTime} />,
        },
      ]}
      api={community}
      intSchoolId={id}
      form={CommunityForm}
      wideModal
    />
  );
}
