import React, { Component } from 'react';
import { Spin } from 'antd';
import QRCode from 'qrcode';

class QrCode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: '',
    };
  }

  componentDidMount() {
    if (this.props.text) {
      this.generateCode(this.props.text);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.text !== nextProps.text) {
      this.setState({ url: '' });
      this.generateCode(nextProps.text);
    }
  }

  generateCode(text) {
    QRCode.toDataURL(text).then(url => this.setState({ url }));
  }

  render() {
    const { width = 120, height = 120, style, className } = this.props;
    return (
      <Spin spinning={!this.state.url}>
        <img
          className={className}
          style={{ width, height, ...style }}
          src={this.state.url}
          alt={this.props.text || ''}
        />
      </Spin>
    );
  }
}

export default QrCode;
