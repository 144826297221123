/* eslint-disable max-classes-per-file */
import React from 'react';

import { Input, Form, Popover } from 'antd';
import RichText from '../../component/rich-text';
import FormImage from '../../component/upload-image';
import { student } from '../../services/intschool';
import ItemListContainer from './component/item_list_container';
import QrCode from '../../component/qrcode';
import Time from '../../component/time';
import { appConfig } from '../../common/config';

const { Item } = Form;

function GraduateForm({ disabled }) {
  return (
    <>
      <Item label="封面" name="coverUrl" rules={[{ required: true, whitespace: true, message: '请选择封面' }]}>
        <FormImage disabled={disabled} preset="common-image" max={1} />
      </Item>
      <Item label="标题" name="title" rules={[{ required: true, whitespace: true, message: '请输入标题' }]}>
        <Input disabled={disabled} />
      </Item>
      <Item
        label="正文"
        name="content"
        rules={[
          {
            required: true,
            // eslint-disable-next-line prefer-promise-reject-errors
            validator: (rule, value) => (RichText.isEmpty(value) ? Promise.reject('请输入正文') : Promise.resolve()),
          },
        ]}
      >
        <RichText disabled={disabled} />
      </Item>
    </>
  );
}

export default function GraduatesList({
  match: {
    params: { id },
  },
}) {
  return (
    <ItemListContainer
      name="优秀毕业生"
      columns={[
        {
          title: '标题',
          key: 'title',
          render: (value, { id, isReviewData, pvcode = '' }) => {
            if (!isReviewData) {
              return value;
            }
            return (
              <Popover
                trigger="click"
                content={
                  <div style={{ textAlign: 'center' }}>
                    <span>手机扫码查看页面</span>
                    <QrCode
                      text={`${appConfig.frontend}/international-school/student/item/${id}?previewCode=${pvcode}`}
                    />
                  </div>
                }
              >
                <span className="color-blue6">{value}</span>
              </Popover>
            );
          },
        },
        {
          title: '创建时间',
          key: 'createTime',
          width: '120px',
          render: createTime => <Time time={createTime} />,
        },
      ]}
      api={student}
      intSchoolId={id}
      form={GraduateForm}
      wideModal
    />
  );
}
