import React from 'react';
import { PlusOutlined, EyeOutlined, DeleteOutlined, FileImageOutlined } from '@ant-design/icons';
import { Upload, Modal, Space, Spin } from 'antd';
import _ from 'lodash';
import './upload_image.less';
import { appConfig } from '../../common/config';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const toCompactArray = value => _.compact(_.castArray(value));

function FileContent({ file }) {
  if (file.status === 'done') {
    return <img src={file.url || ''} alt="" />;
  }
  if (file.status === 'uploading') {
    return <Spin />;
  }
  if (file.status === 'error') {
    return (
      <>
        <FileImageOutlined />
        <span>上传失败</span>
      </>
    );
  }
  if (file.status === 'removed') {
    return null;
  }
  return null;
}

export default class UploadImage extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      previewVisible: false,
      previewImage: '',
      fileList: [],
    };
    this.setFileList = this.setFileList.bind(this);
  }

  componentDidMount() {
    this.setFileList(null, this.props.value);
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.value, this.props.value)) {
      this.setFileList(this.props.value, nextProps.value);
    }
  }

  setFileList(prevValue, nextValue) {
    const { fileList } = this.state;
    const currentFileUrls = new Set(_.compact(_.map(fileList, 'url')));

    // 将value处理成非空值的数组
    prevValue = toCompactArray(prevValue);
    nextValue = toCompactArray(nextValue);

    // 新增的部分，如果当前fileList中没有，需要新增条目
    const addition = _.difference(nextValue, prevValue).filter(url => !currentFileUrls.has(url));
    // 删除的部分，如果当前fileList中有，需要删除
    const deletion = _.difference(prevValue, nextValue).filter(url => currentFileUrls.has(url));

    const fileListAfterDeletion = fileList.filter(file => !deletion.includes(file.url));
    const nextFileList = fileListAfterDeletion.concat(
      addition.map(url => ({
        uid: -1,
        name: 'xxx.png',
        status: 'done',
        response: 'Stub Response', // custom error message to show
        url,
      }))
    );
    this.setState({ fileList: nextFileList });
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file, e) => {
    e.stopPropagation();
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = newState => {
    for (const file of newState.fileList) {
      if (!file.url && file.status === 'done') {
        if (file.response.data?.uri) {
          file.url = file.response.data.uri;
        } else {
          // eslint-disable-next-line no-console
          console.log('文件上传失败', file.response);
          file.status = 'error';
        }
      }
    }
    this.setState({ fileList: [...newState.fileList] }, this.afterValueChanged);
  };

  handleDelete = file => {
    this.setState(({ fileList }) => ({ fileList: fileList.filter(f => f !== file) }), this.afterValueChanged);
  };

  afterValueChanged = () => {
    const { onChange, max } = this.props;
    if (onChange instanceof Function) {
      const { fileList } = this.state;
      const hasError = fileList.some(file => file.status === 'error');
      const uploading = fileList.some(file => file.status === 'uploading');
      if (!uploading && !hasError) {
        const values = fileList.filter(f => f.url).map(f => f.url);
        onChange(max === 1 ? values[0] : values);
      }
    }
  };

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const { max, preset, className, disabled = false, style } = this.props;
    const baseClassName = 'upload-image';
    return (
      <div className={`${baseClassName} ${className || ''}`}>
        {fileList.map((file, idx) => (
          <div key={idx} className={`upload-image-file status-${file.status}`} style={{ ...style }}>
            <div className="upload-image-file-inner">
              <FileContent file={file} />
              <div className="upload-image-file-overlay">
                <div className="upload-image-file-controls">
                  <Space>
                    <EyeOutlined onClick={e => this.handlePreview(file, e)} />
                    {!disabled && <DeleteOutlined onClick={() => this.handleDelete(file)} />}
                  </Space>
                </div>
              </div>
            </div>
          </div>
        ))}
        <Upload
          accept="image/*"
          action={`${appConfig.api}/upload/ufile`}
          showUploadList={false}
          fileList={fileList}
          data={{ preset }}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          disabled={disabled}
        >
          {(disabled && fileList.length > 0) || (max && fileList.length >= max) ? null : (
            <div className="upload-image-upload">
              <PlusOutlined />
              <div className="ant-upload-text">点击上传图片</div>
            </div>
          )}
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="PicturesWall" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}
