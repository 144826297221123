import React from 'react';
import { Input, Form } from 'antd';
// import FormImage from '../../component/upload_image';
import FormImage from '../../component/upload-image';
import { faculty } from '../../services/intschool';
import ItemListContainer from './component/item_list_container';
import ImagePreview from '../../component/image_preview';

const { Item } = Form;

function FacultyForm({ disabled }) {
  return (
    <>
      <Item label="老师名字" name="name" rules={[{ required: true, whitespace: true, message: '请输入老师名字' }]}>
        <Input disabled={disabled} />
      </Item>
      <Item label="老师头像" name="avatarUrl" rules={[{ required: true, whitespace: true, message: '请选择老师头像' }]}>
        <FormImage disabled={disabled} preset="common-image" max={1} />
      </Item>
      <Item label="老师简介" name="introduction" rules={[{ required: true, whitespace: true }]}>
        <Input.TextArea disabled={disabled} autosize={{ maxRows: 6, minRows: 3 }} />
      </Item>
    </>
  );
}
export default function FacultysList({
  match: {
    params: { id },
  },
}) {
  return (
    <ItemListContainer
      name="师资力量"
      columns={[
        {
          width: '140px',
          title: '老师头像',
          key: 'avatarUrl',
          compare: true,
          render: value => (value ? <ImagePreview src={value} /> : null),
        },
        { title: '姓名', key: 'name', compare: true },
        { title: '老师简介', key: 'introduction', compare: true },
      ]}
      api={faculty}
      intSchoolId={id}
      form={FacultyForm}
    />
  );
}
