import React from 'react';
import { PageHeader, Table, Popconfirm, Modal, Button, message, Form, Popover, Input, Space } from 'antd';
import _, { isEqual } from 'lodash';
import './item_list_container.less';
import { Storage } from '../../../common/utils';

const REVIEW_STATUS = {
  /** 未提交 */ CREATED: 0,
  /** 已通过 */ ACCEPTED: 1,
  /** 已拒绝 */ REJECTED: 2,
  /** 待审核 */ PENDING: 3,
};

const REVIEW_STATUS_TEXT = {
  /** 未提交 */ [REVIEW_STATUS.CREATED]: '未提交',
  /** 已通过 */ [REVIEW_STATUS.ACCEPTED]: '已通过',
  /** 已拒绝 */ [REVIEW_STATUS.REJECTED]: '已拒绝',
  /** 待审核 */ [REVIEW_STATUS.PENDING]: '待审核',
};

const REVIEW_STATUS_CLASSNAME = {
  /** 未提交 */ [REVIEW_STATUS.CREATED]: '',
  /** 已通过 */ [REVIEW_STATUS.ACCEPTED]: 'color-green6',
  /** 已拒绝 */ [REVIEW_STATUS.REJECTED]: 'color-red6',
  /** 待审核 */ [REVIEW_STATUS.PENDING]: 'color-orange6',
};

const REVIEW_STATUS_FILTERS = Object.values(REVIEW_STATUS).map(value => ({ text: REVIEW_STATUS_TEXT[value], value }));

function DefaultRenderListContent(content) {
  return <span style={{ maxWidth: 120 }}>{content}</span>;
}

export default class IntSchoolItemListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectIds: [],
      item: null,
      data: [],
      isEditModelVisible: false,
      isEditForCreate: false,
      isEditRequestLoading: false,
      isEditOrderForItem: null,
    };
  }

  form = React.createRef();

  isEditRequestLoading = false;

  // 选择
  onSelectItem = rowKeys => {
    this.setState({
      selectIds: rowKeys,
    });
  };

  /**
   * select选择监听
   */
  onSelectChang = path => value => {
    _.set(this.state, path, value);
    this.forceUpdate();
  };

  componentDidMount() {
    this.fetchList();
  }

  fetchList = () => {
    const { api, intSchoolId, transformInput = _.noop } = this.props;
    api.getList({ intSchoolId }).then(async data => {
      for (const item of data) {
        await transformInput(item);
        if (item.reviewData) {
          await transformInput(item.reviewData);
        }
      }
      this.setState({ data });
    });
  };

  canFetchItem = !!this.props.api?.getItem;

  fetchItem = id => {
    const { api, intSchoolId, transformInput = _.noop } = this.props;
    return api.getItem({ id, intSchoolId }).then(async item => {
      if (item) {
        await transformInput(item);
        if (item.reviewData) {
          await transformInput(item.reviewData);
        }
      }
      return item;
    });
  };

  confirmDelete = id => () => {
    const { api, intSchoolId } = this.props;
    api.delete({ id, intSchoolId }).then(res => {
      message.success('删除成功');
      // 删除成功，将删除的id从selectIds中移除
      const { selectIds } = this.state;
      const deleteIndex = selectIds.findIndex(sId => sId === id);
      selectIds.splice(deleteIndex, 1);
      this.setState({ selectIds });

      this.fetchList();
    });
  };

  batchSubmitAudit = ids => () => {
    const { selectIds } = this.state;
    const { api, intSchoolId } = this.props;
    api.submit({ ids: ids || selectIds, intSchoolId }).then(res => {
      message.success('提交审核成功');
      this.fetchList();
    });
  };

  submitAudit = id => this.batchSubmitAudit([id]);

  /**
   * @param {'up'|'down'} direction
   */
  onMoveItem = (id, direction) => () => {
    const { data } = this.state;
    const targetIndex = data.findIndex(item => item.id === id);
    const target = data[targetIndex];
    if (direction === 'up') {
      const exchangeItem = data[targetIndex - 1];
      data.splice(targetIndex - 1, 2, target, exchangeItem);
    } else {
      const exchangeItem = data[targetIndex + 1];
      data.splice(targetIndex, 2, exchangeItem, target);
    }
    this.setState({ data: [...data] });
  };

  openEditModal = (item, nextState) => {
    this.setState({ item, isEditModelVisible: true, ...nextState });
  };

  closeEditModal = () => {
    this.setState({ item: null, isEditModelVisible: false });
  };

  onCreate = async () => {
    const { getInitialValues } = this.props;
    if (typeof getInitialValues === 'function') {
      try {
        const values = await getInitialValues();
        this.openEditModal(values, { isEditForCreate: true });
      } catch (err) {
        message.error(`获取初始数据失败：${err.message}`);
      }
    } else {
      this.openEditModal(null, { isEditForCreate: true });
    }
  };

  onEdit = row => async () => {
    // 如果需要先请求item详情，再编辑
    if (this.canFetchItem) {
      try {
        const data = await this.fetchItem(row.id);
        this.openEditModal({ ...data, ...data.reviewData }, { isEditForCreate: false });
      } catch (err) {
        message.error(`获取单条数据失败：${err.message}`);
      }
    } else {
      this.openEditModal({ ...row, ...row.reviewData }, { isEditForCreate: false });
    }
  };

  onEditOrderVisibleChange = id => visible => {
    this.setState({ isEditOrderForItem: visible ? id : null });
  };

  onEditOrder = id => order => {
    if (!order) {
      message.error('请填写顺序');
      return;
    }
    const value = Number(order);
    if (!Number.isInteger(value) || !(value > 0)) {
      message.error('请填写正确的顺序');
      return;
    }
    const { api, intSchoolId } = this.props;
    api.order({ id, intSchoolId, order }).then(() => {
      message.success('调整顺序成功');
      this.setState({ isEditOrderForItem: null });
      this.fetchList();
    });
  };

  finishEditModal = submit => () => {
    if (typeof submit !== 'boolean') {
      message.error('参数传递错误');
      return;
    }
    const { transformOutput = _.noop } = this.props;
    if (this.isEditRequestLoading) {
      return;
    }
    this.isEditRequestLoading = true;
    this.setState({ isEditRequestLoading: true });
    Promise.resolve(this.form.current)
      .then(async form => {
        const { api, intSchoolId } = this.props;
        const { isEditForCreate } = this.state;
        try {
          const values = await form.validateFields().catch(errorData => {
            message.error(`表单存在${errorData.errorFields.length}处错误，请仔细检查`);
            throw errorData;
          });
          await transformOutput(values);
          let id = values.id;
          if (isEditForCreate) {
            const res = await api.add({ intSchoolId, ...values });
            id = res.id;
          } else {
            await api.modify({ intSchoolId, ...values });
          }

          // 是否提交审核
          if (submit) {
            await api.submit({ ids: [id], intSchoolId });
            message.success('提交审核成功');
          } else {
            message.success('保存成功');
          }
          this.closeEditModal();
          this.fetchList();
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      })
      .finally(() => {
        this.isEditRequestLoading = false;
        this.setState({ isEditRequestLoading: false });
      });
  };

  finishReviewModal = accept => () => {
    if (typeof accept !== 'boolean') {
      message.error('参数传递错误');
      return;
    }
    if (this.isEditRequestLoading) {
      return;
    }
    this.isEditRequestLoading = true;
    this.setState({ isEditRequestLoading: true });
    Promise.resolve()
      .then(async () => {
        const { api, intSchoolId } = this.props;
        const { item } = this.state;
        if (!item || !item.id) {
          message.error('无法获取当前审核对象');
          return;
        }
        try {
          await api.review({ ids: [item.id], intSchoolId, accept });
          message.success('审核成功');
          this.closeEditModal();
          this.fetchList();
        } catch (err) {
          // message.error(err.message);
        }
      })
      .finally(() => {
        this.isEditRequestLoading = false;
        this.setState({ isEditRequestLoading: false });
      });
  };

  renderCompare = (key, render = DefaultRenderListContent) => (__, item) => {
    const nextValue = item?.reviewData?.[key];
    const prevValue = item?.[key];
    if (nextValue != null && prevValue != null && !isEqual(nextValue, prevValue)) {
      return (
        <>
          <Popover
            title="改动之前的值（如果已上线，则表示线上的值）"
            overlayStyle={{ maxWidth: 480 }}
            content={render(prevValue, { ...item, isReviewData: false })}
          >
            <span className="color-orange6" style={{ userSelect: 'none', marginRight: 8 }}>
              [改动]
            </span>
          </Popover>
          <span>{render(nextValue, { ...item, ...item.reviewData, isReviewData: true })}</span>
        </>
      );
    }
    return render(nextValue ?? prevValue, { ...item, ...item.reviewData, isReviewData: true });
  };

  renderValue = (key, render = DefaultRenderListContent) => (__, item) => {
    const nextValue = item?.reviewData?.[key];
    const prevValue = item?.[key];
    return render(nextValue ?? prevValue, { ...item, ...item.reviewData, isReviewData: true });
  };

  renderModal() {
    const { name, wideModal, form: EditForm } = this.props;
    const { item, isEditModelVisible, isEditRequestLoading, isEditForCreate } = this.state;
    const formItemProps = {
      layout: 'horizontal',
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    };
    return (
      <Modal
        visible={isEditModelVisible}
        width={wideModal ? 810 : 540}
        title={isEditForCreate ? `新增${name}` : `编辑${name}`}
        maskClosable={false}
        closable={false}
        destroyOnClose
        footer={[
          <Button key="close" onClick={this.closeEditModal}>
            关闭
          </Button>,
          <Button
            key="save-and-stay"
            type="primary"
            loading={isEditRequestLoading}
            onClick={this.finishEditModal(false)}
          >
            保存
          </Button>,
          <Button
            key="save-and-submit"
            type="primary"
            loading={isEditRequestLoading}
            onClick={this.finishEditModal(true)}
          >
            保存并提交审核
          </Button>,
        ]}
      >
        <Form layout="vertical" ref={this.form} initialValues={item} {...formItemProps}>
          <Form.Item name="id" noStyle />
          <EditForm item={item || {}} />
        </Form>
      </Modal>
    );
  }

  render() {
    const { name, columns, id, className, style, itemDeleteText, hideOrder } = this.props;
    const { data, selectIds, isEditOrderForItem } = this.state;
    const schoolName = Storage.getItem('schoolName') || '学校';

    const totalCount = data.length;
    const selectedCount = selectIds.length;

    const hasSelectedIds = totalCount > 0 && selectedCount > 0;
    const dataMapping = data.reduce((memo, cur) => {
      memo[cur.id] = cur;
      return memo;
    }, {});
    const canBatchSubmit =
      hasSelectedIds && selectIds.every(id => dataMapping[id].reviewStatus !== REVIEW_STATUS.PENDING);

    return (
      <div {...{ id, className: `container-international-school-item-list ${className || ''}`, style }}>
        <PageHeader
          style={{ borderTop: '1px solid #edf0f2', background: 'white' }}
          title={`${schoolName}-${name}管理`}
          extra={[
            <Button key="create" onClick={this.onCreate}>
              新增{`${name}`}
            </Button>,
          ]}
        />
        <div className="content-white-box">
          <div className="batch-box">
            <Popconfirm
              disabled={!canBatchSubmit}
              title={`确定要提交审核选中的${selectedCount}条数据吗？`}
              onConfirm={this.batchSubmitAudit()}
            >
              <Button type="primary" disabled={!canBatchSubmit}>
                批量提交审核
              </Button>
            </Popconfirm>
          </div>
          <Table
            pagination={false}
            dataSource={data}
            rowKey="id"
            rowSelection={{
              onChange: this.onSelectItem,
              selectedRowKeys: selectIds,
            }}
            columns={[
              { width: '80px', title: 'ID', key: 'id', dataIndex: 'id', align: 'right' },
              ...columns.map(({ title, key, render, compare, ellipsis = true, ...options }) => ({
                title,
                key,
                dataIndex: key,
                ...options,
                ellipsis,
                render: compare ? this.renderCompare(key, render) : this.renderValue(key, render),
              })),
              ...(hideOrder
                ? []
                : [
                    {
                      width: '65px',
                      title: '顺序',
                      key: 'order',
                      dataIndex: 'order',
                      align: 'center',
                      render: (order, row) => (
                        <Popover
                          trigger="click"
                          title="修改位置"
                          destroyTooltipOnHide
                          visible={isEditOrderForItem === row.id}
                          onVisibleChange={this.onEditOrderVisibleChange(row.id)}
                          content={
                            <Input.Search
                              placeholder="输入新的位置"
                              enterButton="确定"
                              onSearch={this.onEditOrder(row.id)}
                            />
                          }
                        >
                          <span className="color-blue6 cur">{order}</span>
                        </Popover>
                      ),
                    },
                  ]),
              {
                title: '审核',
                key: 'reviewStatus',
                dataIndex: 'reviewStatus',
                align: 'center',
                width: '80px',
                filters: REVIEW_STATUS_FILTERS,
                onFilter: (value, record) => record.reviewStatus === value,
                render: reviewStatus => (
                  <span className={REVIEW_STATUS_CLASSNAME[reviewStatus]}>{REVIEW_STATUS_TEXT[reviewStatus]}</span>
                ),
              },
              {
                title: '状态',
                key: 'enabled',
                align: 'center',
                width: '75px',
                render: row => (
                  <span className={`${row.enabled ? 'color-green6' : 'color-red6'}`}>
                    {row.enabled ? '已上线' : '未上线'}
                  </span>
                ),
              },
              {
                title: '操作',
                key: 'handle',
                align: 'center',
                width: 200,
                render: row => {
                  const needAdminPermission =
                    row.enabled ||
                    row.reviewStatus === REVIEW_STATUS.ACCEPTED ||
                    row.reviewStatus === REVIEW_STATUS.PENDING;

                  return (
                    <Space className="span-box">
                      <span className="color-blue6 cur" onClick={this.onEdit(row)}>
                        编辑
                      </span>
                      {row.reviewStatus === REVIEW_STATUS.PENDING ? null : (
                        <Popconfirm title="确定要提交审核吗？" onConfirm={this.submitAudit(row.id)}>
                          <span className="color-blue6 cur">提交审核</span>
                        </Popconfirm>
                      )}
                      {needAdminPermission ? null : (
                        <Popconfirm title={itemDeleteText ?? '确定要删除吗？'} onConfirm={this.confirmDelete(row.id)}>
                          <span className="color-red6 cur">删除</span>
                        </Popconfirm>
                      )}
                    </Space>
                  );
                },
              },
            ]}
          />
        </div>
        {this.renderModal()}
      </div>
    );
  }
}
