import React from 'react';
import './browser-tip.less';

export default function BrowserTip() {
  return (
    <div className="browser-tip-component">
      <div className="container-box" />
      <div className="container">
        <div className="chrome-tip">
          <span>请使用</span>
          <a
            href="https://www.baidu.com/s?ie=UTF-8&wd=chrome%E6%B5%8F%E8%A7%88%E5%99%A8%20%E4%B8%8B%E8%BD%BD"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chrome 浏览器
          </a>
          <span>以获取最佳使用体验</span>
        </div>
      </div>
    </div>
  );
}
