import React from 'react';
import { Input, Form } from 'antd';
import { faq } from '../../services/intschool';
import ItemListContainer from './component/item_list_container';

function FrequentQuestionForm() {
  return (
    <>
      <Form.Item label="问题" name="question" rules={[{ required: true, whitespace: true, message: '请输入问题' }]}>
        <Input />
      </Form.Item>

      <Form.Item label="回答" name="answer" rules={[{ required: true, whitespace: true, message: '请输入回答' }]}>
        <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
      </Form.Item>
    </>
  );
}
export default function FrequentQuestionList({
  match: {
    params: { id },
  },
}) {
  return (
    <ItemListContainer
      name="常见问题"
      columns={[
        { title: '问题', key: 'question', compare: true },
        { title: '回答', key: 'answer', compare: true },
      ]}
      api={faq}
      intSchoolId={id}
      form={FrequentQuestionForm}
    />
  );
}
