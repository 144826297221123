/** 基本状态 */
const status = {
  /** 已删除 */ DELETED: -6,
  /** 已隐藏 */ HIDDEN: -5,
  /** 已禁用 */ DISABLED: -4,
  /** 已拒绝 */ REJECTED: -3,
  /** 待审核 */ REVIEWING: -2,
  /** 未提交审核 */ CREATED: -1,
  /** 已审核 */ NORMAL: 0,
  /** 更新待审核 */ UPDATE_REVIEWING: 1,
  /** 更新已拒绝 */ UPDATE_REJECTED: 2,
};
const statusText = {
  [status.HIDDEN]: '已被隐藏',
  [status.DISABLED]: '已被禁用',
  [status.REJECTED]: '已被拒绝',
  [status.REVIEWING]: '审核中',
  [status.CREATED]: '草稿',
  [status.NORMAL]: '已审核通过',
  [status.UPDATE_REVIEWING]: '审核中',
  [status.UPDATE_REJECTED]: '已被拒绝',
};
const statusString = {
  [status.HIDDEN]: 'hidden',
  [status.DISABLED]: 'disabled',
  [status.REJECTED]: 'rejected',
  [status.REVIEWING]: 'reviewing',
  [status.CREATED]: 'created',
  [status.NORMAL]: 'normal',
  [status.UPDATE_REVIEWING]: 'update_reviewing',
  [status.UPDATE_REJECTED]: 'update_rejected',
};
const imgAccept = 'image/jpeg,image/png,image/webp,image/gif';
const contractAccept = '*';

// 预约状态
const appointmentStatus = {
  /** 无法签约 */ CANNOT_SIGN: -3,
  /** 无法联系 */ CANNOT_CONTACT: -2,
  /** 已取消 */ CANCELLED: -1,
  /** 待联系 */ WAITING: 0,
  /** 已联系 */ CONTACTED: 1,
  /** 已评价（该状态已禁用） */ REVIEWED: 2,
  /** 已上门 */ VISITED: 3,
  /** 已签约 */ SIGNED: 4,
};
const appointmentStatusText = {
  [appointmentStatus.CANNOT_SIGN]: '无法签约',
  [appointmentStatus.CANNOT_CONTACT]: '无法联系',
  [appointmentStatus.CANCELLED]: '已取消',
  [appointmentStatus.WAITING]: '待联系',
  [appointmentStatus.CONTACTED]: '已联系',
  [appointmentStatus.REVIEWED]: '已评价（该状态已禁用）',
  [appointmentStatus.VISITED]: '已上门',
  [appointmentStatus.SIGNED]: '已成交',
};
const appointmentStatusString = {
  [appointmentStatus.CANNOT_SIGN]: 'cannot_sign',
  [appointmentStatus.CANNOT_CONTACT]: 'cannot_contact',
  [appointmentStatus.CANCELLED]: 'cancelled',
  [appointmentStatus.WAITING]: 'waiting',
  [appointmentStatus.CONTACTED]: 'contacted',
  [appointmentStatus.REVIEWED]: 'reviewed',
  [appointmentStatus.VISITED]: 'visited',
  [appointmentStatus.SIGNED]: 'signed',
};

/** 合同状态 */
const contractStatus = {
  /** 已删除 */ DELETED: status.DELETED,
  /** 已拒绝 */ REJECTED: status.REJECTED,
  /** 待审核 */ REVIEWING: status.REVIEWING,
  /** 已审核 */ NORMAL: status.NORMAL,
};
const contractStatusText = {
  [status.DELETED]: '已删除',
  [status.REJECTED]: '已拒绝',
  [status.REVIEWING]: '待审核',
  [status.NORMAL]: '已审核',
};
const contractStatusString = {
  [status.DELETED]: 'deleted',
  [status.REJECTED]: 'rejected',
  [status.REVIEWING]: 'reviewing',
  [status.NORMAL]: 'normal',
};
export default {
  storageKey: {
    TICKET: 'ticket',
    USERINFO: 'userInfo',
    STATUS: 'status',
  },
  status,
  statusText,
  statusString,
  imgAccept,
  contractAccept,
  appointmentStatus,
  appointmentStatusText,
  appointmentStatusString,
  contractStatus,
  contractStatusText,
  contractStatusString,
};
