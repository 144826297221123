import React from 'react';
import Queue from 'p-queue';
import { message } from 'antd';
import { bmapCreateMap, bmapCreateLocalSearch } from '../common/baidumap';

export default class BaiduMapSearch extends React.PureComponent {
  ref = React.createRef();

  queue = new Queue({ concurrency: 1, autoStart: true });

  map = null;

  local = null;

  componentDidMount() {
    const { city } = this.props;
    if (!city) {
      throw new Error('请选择好城市再打开地图');
    }
    this.queue.add(this.initMap);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.address && nextProps.address !== this.props.address) {
      if (this.local) {
        this.local.search(nextProps.address);
      }
    }
  }

  initMap = () => {
    const { address, city } = this.props;
    bmapCreateMap(this.ref.current).then(
      async map => {
        this.map = map;
        map.centerAndZoom(city);

        const local = await bmapCreateLocalSearch(city, {
          renderOptions: { map },
          onMarkersSet: this.onMarkersSet,
        });
        this.local = local;

        if (address) {
          local.search(address);
        }
      },
      err => {
        message.error(err.message);
      }
    );
  };

  onMarkersSet = pois => {
    pois.forEach(poi => {
      poi.marker.addEventListener('infowindowopen', () => {
        this.onMarkerOpen(poi);
      });
    });
  };

  onMarkerOpen = poi => {
    const { onLocationSelect } = this.props;
    if (typeof onLocationSelect === 'function') {
      const {
        title,
        address,
        point: { lng, lat },
        city,
        province,
      } = poi;

      onLocationSelect({
        title,
        address,
        point: { lng, lat },
        city,
        province,
      });
    }
  };

  render() {
    const { width = 640, height = 480 } = this.props;
    return <div ref={this.ref} style={{ width, height }} />;
  }
}
